import { v4 as uuidv4 } from 'uuid';

export class Document {
    id: string | undefined;
    rangeId: string | undefined;

    documentTitle: string | undefined;

    s3Key: string | undefined;

    active: boolean = true;
    createdAt: string | undefined;
    createdBy: string | undefined;
    modifiedAt: string | undefined;
    modifiedBy: string | undefined;
}

export enum ReferenceType {
    BUSINESS_HOME ='businessHome',
    CAREGIVER = 'caregiver',
    RESIDENT = 'resident',
}

export function convertFileToDocument(file: File, referenceId: string, referenceType: ReferenceType) {
    var document: Document = new Document();
    const uniqueId = uuidv4()

    document.active = true;
    document.id = referenceId;
    document.rangeId = uniqueId;
    document.documentTitle = file.name;
    document.s3Key = `${referenceType}/${referenceId}/${uniqueId}`;

    return document;
}