import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./contexts/authContext";
import { RouterAddress } from "./constants/RouterAddress";
import Login from "./components/login/login";
import { inject, observer } from "mobx-react";
import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";
import { PrivateRoutes } from "./routes/authRouter";
import NotSignedIn from "./routes/notSignedIn";
import DashboardProvider from "./routes/dashboardProvider";
import DashboardCaregiver from "./routes/dashboardCaregiver";
import DashboardResidents from "./routes/dashboardResidents";

@inject("authStore")
@observer
export class App extends React.Component<any, any> {
  render() {
    const { authStore } = this.props;
    return (
      <AuthContextProvider {...authStore}>
        <BrowserRouter>
          <Routes>
            <Route path={RouterAddress.LOGIN_PAGE} element={<Login />} />
            <Route element={<PrivateRoutes authStore={authStore} />}>
              <Route
                path={RouterAddress.DASHBOARD_PROVIDER}
                element={<DashboardProvider />}
              />
              <Route
                path={RouterAddress.DASHBOARD_HOME_CAREGIVER}
                element={<DashboardCaregiver />}
              />
              <Route
                path={RouterAddress.DASHBOARD_HOME_RESIDENTS}
                element={<DashboardResidents />}
              />
            </Route>
            <Route
              path={RouterAddress.NOT_SIGNED_IN}
              element={<NotSignedIn />}
            />
            <Route
              path="*"
              element={<NotSignedIn />}
            />
          </Routes>
          <MDBFooter className="text-center text-white footerContainer" >
            <div className="text-center p-3">
              © 2024 Copyright - AdultFamilyHomeHub.com
              <p className="p-1">
                Contact us: <a href="mailto:adultfamilieshome@gmail.com">adultfamilieshome@gmail.com</a>
              </p>
            </div>
            
          </MDBFooter>
        </BrowserRouter>
      </AuthContextProvider>
    );
  }
}
