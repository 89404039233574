import React, { Component } from 'react';
import { 
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardSubTitle,
  MDBCardLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBCheckbox,
  MDBTooltip,
  MDBFile,
  MDBCardImage,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBRadio,
  MDBTextArea,
  MDBCardHeader,
  MDBCardFooter,
} from 'mdb-react-ui-kit';
import { inject } from 'mobx-react';
import { ResidentProfile } from '../models/residentProfile';
import { useParams } from "react-router-dom";
import { BusinessHomeProfile } from '../models/businessHomeProfile';
import { convertIsoToDate, showPluralIfNeeded } from '../utils/stringUtils';
import { convertFileToDocument, Document, ReferenceType } from '../models/document';

function withParams(Component: any) {
  return (props) => <Component {...props} params={useParams()} />;
}

@inject('backendAPIStore')
export class DashboardResidents extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addNewResidentModalVisiblility: false,
      residents: [],
      residentDetails: ResidentProfile,
      errorMessageAddNewResident: '',
      editEnabled: false,
      businessHomeDetails: BusinessHomeProfile,
      selectedFile: null,
      previewUrl: null,

      documentModalVisiblility: false,
      documents: [],
      newDocuments: [],
      newDocumentUploadButtonDisable: [],
    }

    this.toggleNewResidentModalVisiblility = this.toggleNewResidentModalVisiblility.bind(this);
    this.setResidentDetails = this.setResidentDetails.bind(this);
    this.submitNewResidentDetails = this.submitNewResidentDetails.bind(this);
    this.validateNewResidentDetails = this.validateNewResidentDetails.bind(this);
    this.residentListHTML = this.residentListHTML.bind(this);
    this.noResidentListHTML = this.noResidentListHTML.bind(this);
    this.editResidentDetails = this.editResidentDetails.bind(this);
    this.deleteResidentDetails = this.deleteResidentDetails.bind(this);
    this.manageDocuments = this.manageDocuments.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleNewDocuments = this.handleNewDocuments.bind(this)

    this.toggleDocumentModalVisibility = this.toggleDocumentModalVisibility.bind(this);
    this.handleUploadProfilePic = this.handleUploadProfilePic.bind(this);
    this.onSubmitNewDocuments = this.onSubmitNewDocuments.bind(this);
    this.documentListHTML = this.documentListHTML.bind(this);

    this.downloadDocument = this.downloadDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
  }

  async componentDidMount(){
    this.setState({residents: []});
    await this.props.backendAPIStore.init()
    const residentsResponse = await this.props.backendAPIStore.getResidents(this.props.params.businessHomeRangeId);
    const businessHomeResponse = await this.props.backendAPIStore.getBusinessHomeDetails(this.props.params.businessHomeRangeId);
    this.setState({
      residents: residentsResponse,
      businessHomeRangeId: this.props.params.businessHomeRangeId,
      businessHomeDetails: businessHomeResponse
    }, () => {
      console.log(this.state.residents);
    });
  }

  toggleNewResidentModalVisiblility(visible: boolean) {
    this.setState({
      addNewResidentModalVisiblility: visible,
      residentDetails: new ResidentProfile(),
      errorMessageAddNewResident: '',
      editEnabled: false,
      selectedFile: null,
      previewUrl: null,
    }, () => {
      this.setState({residentDetails: {...this.state.residentDetails, id: this.state.businessHomeRangeId}}, () => {
        
      })
    });
  }

  handleFileChange = (event) => {
    if (event.target.files.length !== 1) {
      return;
    }

    const file = event.target.files[0];
    if(file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.setState({errorMessageAddNewResident: 'Please only upload image file. Supported file types are JPG, JPEG & PNG'});
      return;
    }

    this.setState({
      selectedFile: event.target.files[0],
      previewUrl: URL.createObjectURL(event.target.files[0]),
    });
  };

  handleNewDocuments = (event) => {
    if (event.target.files.length == 0) {
      return;
    }

    this.setState({
      newDocumentUploadButtonDisable: false,
      newDocuments: event.target.files,
    });
  }

  onSubmitNewDocuments = async (event) => {
    if (this.state.newDocuments.length == 0) {
      return;
    }

    await this.processAllDocument();
    this.setState({
      newDocuments: [],
      toggleDocumentModalVisibility: false,
      documents: await this.props.backendAPIStore.listAllDocument(this.state.residentDetails.rangeId),
    });
  }

  async processAllDocument() {
    for (let index = 0; index < this.state.newDocuments.length; index++) {
      await this.processDocument(this.state.newDocuments[index]);
    }
  }

  async processDocument(file: File) {
    const document = convertFileToDocument(file, this.state.residentDetails.rangeId, ReferenceType.RESIDENT);
    const dbResponse = await this.props.backendAPIStore.addDocument(document);
    const uploadUrl = await this.props.backendAPIStore.getPresignedUrlForUpload(this.props.params.businessHomeRangeId, document.s3Key);
    await this.props.backendAPIStore.uploadFileToS3(uploadUrl, file);
  }

  handleUploadProfilePic = async (event) => {
    if (this.state.selectedFile) {
      const url = await this.props.backendAPIStore.getPresignedUrlForUpload(this.props.params.businessHomeRangeId, 'resident/pic/' + this.state.residentDetails.rangeId);
      await this.props.backendAPIStore.uploadFileToS3(url, this.state.selectedFile);
    }
  };

  setResidentDetails(event: any) {
    this.setState({ residentDetails: {...this.state.residentDetails, [event.target.name]: event.target.value }})
  }

  async submitNewResidentDetails(e: any) {
    const isValidInput = this.validateNewResidentDetails()
    if (!isValidInput) {
      return;
    }

    if (!this.state.editEnabled) {
      await this.props.backendAPIStore.addResident(this.state.residentDetails);
    } else {
      await this.props.backendAPIStore.updateResident(this.state.residentDetails);
    }
    
    const response = await this.props.backendAPIStore.getResidents(this.props.params.businessHomeRangeId);
    this.setState({residents: response}, () => {
    });
    this.toggleNewResidentModalVisiblility(false);
  }

  validateNewResidentDetails() {
    if (!this.state.residentDetails 
      || !this.state.residentDetails.firstName
      || !this.state.residentDetails.lastName
      || !this.state.residentDetails.email
      || !this.state.residentDetails.phoneNumber
      || !this.state.residentDetails.addressLine1
      || !this.state.residentDetails.city
      || !this.state.residentDetails.zipcode
    ) {
        this.setState({errorMessageAddNewResident: 'Please provide all the required inputs.'});
        return false;
    }

    this.setState({errorMessageAddNewResident: ''})
    return true;
  }

  editResidentDetails(resident: ResidentProfile) {
    this.setState({
      addNewResidentModalVisiblility: true, 
      editEnabled: true,
      residentDetails: resident,
      previewUrl: null,
    }, async () => {
      const url = await this.props.backendAPIStore.getPresignedUrlForDownload(this.props.params.businessHomeRangeId, 'resident/pic/' + this.state.residentDetails.rangeId);
      this.setState({previewUrl: url})
    });
  }

  deleteResidentDetails(resident: ResidentProfile) {

  }

  manageDocuments(resident: ResidentProfile) {
    this.setState({
      residentDetails: resident,
    }, async () => {
      this.setState({
        documents: await this.props.backendAPIStore.listAllDocument(this.state.residentDetails.rangeId)
      }, () => {
        this.setState({documentModalVisiblility: true});
      })
    });
  }

  async downloadDocument(document: Document) {
    const s3Key = `${ReferenceType.RESIDENT}/${document.id}/${document.rangeId}`
    const url = await this.props.backendAPIStore.getPresignedUrlForDownload(this.props.params.businessHomeRangeId, s3Key);
    window.open(url);
  }

  async deleteDocument(document: Document) {
    await this.props.backendAPIStore.deleteDocument(document);
    this.setState({
      documents: await this.props.backendAPIStore.listAllDocument(this.state.residentDetails.rangeId)
    });
  }

  toggleDocumentModalVisibility(visible: boolean) {
    this.setState({
      documentModalVisiblility: visible,
      newDocumentUploadButtonDisable: true,
    }, async () => {
      
    });
  }



  noResidentListHTML() {
    return (
      <div>
        <div>
          Looks like you haven't added any resident yet. 
        </div>
        <MDBBtn onClick={() => this.toggleNewResidentModalVisiblility(true)}>
          Add new resident
        </MDBBtn>
      </div>
    )
  }

  residentListHTML() {
    return (
      <div>
        <div style={{margin: "1em"}}>
          <div>
            You have {this.state.residents.length} registered {showPluralIfNeeded(this.state.residents.length, 'resident')} for Home: <strong>{this.state.businessHomeDetails.businessName}</strong>
            {
              !this.state.businessHomeDetails.active ? <div style={{color: "red"}}>&nbsp;This home is not active</div>: null
            }
          </div>
          <MDBBtn onClick={() => this.toggleNewResidentModalVisiblility(true)} style={{margin: "1em", clear: "both"}}>
            Add new resident
          </MDBBtn>
        </div>
        {
          this.state.residents.map((resident: ResidentProfile) => (
            <MDBCard key={resident.firstName} className='screen' style={{margin: "1em", cursor: "pointer", textAlign: "left"}}>
              {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/184.webp' position='top' alt='...' style={{width: "15em", height: "15em"}}/> */}
              <MDBCardBody>
                <MDBCardTitle>
                  {resident.firstName}&nbsp;{resident.lastName}
                 <MDBDropdown group className="float-end">
                    <MDBDropdownToggle color='info'>Action</MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link onClick={() => this.editResidentDetails(resident)}>Edit Details</MDBDropdownItem>
                      <MDBDropdownItem link onClick={() => this.manageDocuments(resident)}>Manage Documents</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBCardTitle>
                <MDBCardSubTitle>
                  {resident.addressLine1}, {resident.city}, {resident.state} - {resident.zipcode}
                </MDBCardSubTitle>
                <MDBCardText>
                  {
                    !resident.active ? (
                      <div>
                        <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title="Resident is not active. To make it active, edit resident details.">
                          <span style={{color: 'red'}}>Resident is not Active</span>
                        </MDBTooltip>
                      </div>)
                      : null
                  }
                </MDBCardText>
              </MDBCardBody>
          </MDBCard>
        ))}
      </div>
    )
  }

  documentListHTML() {
    return (
      <div>
        <h4>List of Documents:</h4>
        {
          this.state.documents.map((document: Document, index: number) => (
          <MDBCard style={{margin: "1em"}}>
              <MDBCardBody>
                <MDBCardText>
                  {document.documentTitle}
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter className='text-muted'>
                {convertIsoToDate(document.createdAt)}
                <div className='float-end'>
                  <span onClick={() => this.downloadDocument(document)} title='Download'>
                    <MDBIcon fas icon="cloud-download-alt" style={{color: "gray", cursor: "pointer"}}/>
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span onClick={() => this.deleteDocument(document)} title='Delete'>
                    <MDBIcon fas icon="trash-alt" style={{color: "red", cursor: "pointer"}}/>
                  </span>
                </div>
              </MDBCardFooter>
            </MDBCard>
          ))
        }
      </div>
    )
  }
  
  render() {
    return (
      <div style={{textAlign: 'center'}} className="pageContainer">
        {
          this.state.residents.length === 0 ? ( this.noResidentListHTML() ): this.residentListHTML()
        }
          
        <MDBModal open={this.state.addNewResidentModalVisiblility} onClose={()=>this.toggleNewResidentModalVisiblility(false)} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Resident Details</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={()=>this.toggleNewResidentModalVisiblility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                { this.state.editEnabled ? (
                  <div>
                    {
                      (this.state.selectedFile || this.state.previewUrl) ? 
                      <img src={this.state.previewUrl} style={{width: "15em", height: "15em"}} />: null
                    }
                    <MDBFile id='customFile' onChange={(e)=>this.handleFileChange(e)} />
                    <MDBBtn onClick={(e)=>this.handleUploadProfilePic(e)} disabled={!this.state.selectedFile}>Upload</MDBBtn>
                    <br/>
                  </div>): null
                }
                <MDBValidation className="row g-3 needs-validation">
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="First Name"
                      name="firstName"
                      id="firstName"
                      type="text"
                      value={this.state.residentDetails.firstName}
                      required
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Last Name"
                      name="lastName"
                      id="lastName"
                      type="text"
                      value={this.state.residentDetails.lastName}
                      required
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Email"
                      name="email"
                      id="email"
                      type="email"
                      value={this.state.residentDetails.email}
                      required
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Phone Number"
                      name="phoneNumber"
                      id="phoneNumber"
                      type="number"
                      value={this.state.residentDetails.phoneNumber}
                      required
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Address Line 1"
                      name="addressLine1"
                      id="addressLine1"
                      type="text"
                      value={this.state.residentDetails.addressLine1}
                      required
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Address Line 2"
                      name="addressLine2"
                      id="addressLine2"
                      type="text"
                      value={this.state.residentDetails.addressLine2}
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="City"
                      name="city"
                      id="city"
                      type="text"
                      value={this.state.residentDetails.city}
                      required
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="State"
                      name="state"
                      id="state"
                      type="text"
                      value={this.state.residentDetails.state}
                      required
                      disabled
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Country"
                      name="country"
                      id="country"
                      type="text"
                      value={this.state.residentDetails.country}
                      required
                      disabled
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="zipcode"
                      name="zipcode"
                      id="zipcode"
                      type="text"
                      value={this.state.residentDetails.zipcode}
                      required
                      onChange={(event) => this.setResidentDetails(event)}
                    />
                  </MDBValidationItem>
                  {
                    this.state.editEnabled ? (
                      <MDBValidationItem feedback="" invalid>
                        <MDBCheckbox 
                        name='active' 
                        checked={this.state.residentDetails.active} 
                        id='active' 
                        label='Active' 
                        onChange={(event) => {
                          this.setState({ residentDetails: {...this.state.residentDetails, [event.target.name]: event.target.checked }})
                        }}/>
                      </MDBValidationItem>
                    ): null
                  }
                  
                  <MDBModalFooter>
                    <MDBBtn color='secondary' onClick={()=>this.toggleNewResidentModalVisiblility(false)}>
                      Cancel
                    </MDBBtn>
                    <MDBBtn type='submit' onClick={(e) => this.submitNewResidentDetails(e)}>Submit</MDBBtn>
                    <div style={{color: 'red'}}>{this.state.errorMessageAddNewResident}</div>
                  </MDBModalFooter>
                </MDBValidation>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal open={this.state.documentModalVisiblility} onClose={()=>this.toggleDocumentModalVisibility(false)} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Documents Uploaded: {this.state.documents.length}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={()=>this.toggleDocumentModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBCard style={{margin: "1em"}}>
                  <MDBCardBody>
                    <MDBCardTitle>
                      Add new documents
                    </MDBCardTitle>
                    
                    <MDBCardText>
                      <MDBFile id='customFileNewDocuments' onChange={(e)=>this.handleNewDocuments(e)} multiple/>
                        <br />
                      <MDBBtn onClick={(e)=>this.onSubmitNewDocuments(e)} disabled={this.state.newDocumentUploadButtonDisable}>Upload</MDBBtn>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
                {
                  this.state.documents.length > 0 ? (this.documentListHTML()): null
                }
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    )
  };
}

export default withParams(DashboardResidents);
