export function isNullEmptyUndefinedString(var1: string): boolean {
  return !var1 || var1.trim().length === 0;
}

export function textContainLowerCaseCharacter(text: string): boolean {
  const containLowerCase: RegExp = /[a-z]/g;
  return containLowerCase.test(text);
}

export function textContainNumber(text: string): boolean {
  const containNumber: RegExp = /[0-9]/g;
  return containNumber.test(text);
}

export function textContainSpecialCharacter(text: string): boolean {
  const containSpecial: RegExp = /[\^$*.\[\]{}()?\-"!@#%&/\\,><':;|_~`]/g;
  return containSpecial.test(text);
}

export function textContainUpperCaseCharacter(text: string): boolean {
  const containUpperCase: RegExp = /[A-Z]/g;
  return containUpperCase.test(text);
}
export function textContainNumberOnly(text: string): boolean {
  const isNumber: RegExp = /^[0-9]+$/gm;
  return isNumber.test(text);
}

export function textIsLongEnough(text: string, length: number) {
  if (!text) {
    return false;
  }
  return text.length >= length;
}
