import * as React from "react";
import { GenericModal } from "../general/genericModal";
import {
  MDBBtn,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBTooltip,
} from "mdb-react-ui-kit";
import { isNullEmptyUndefinedString } from "../../utils/validationUtils";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

export interface IRegisterActionModal {
  isVisible: boolean;
  submit: (userdetails: any) => Promise<any> | void;
  dismiss: () => any;
  errorMessage?: string;
  cognitoHelper: any;
}

class RegisterAuthModal extends React.Component<IRegisterActionModal, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      authInfo: "",
      authErrorMessage: "",
      authInfoMessage: "",
      timerStatus: false,
      username: "",
      userEmailAddress: "",
      userPassword: "",
      userConfirmPassword: "",
    };

    this.submit = this.submit.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  async submit(): Promise<any> {
    this.setState({
      authErrorMessage: "",
      authInfoMessage: "",
    });
    try {
      if (isNullEmptyUndefinedString(this.state.userEmailAddress)) {
        this.setState({
          authInfoMessage: "",
          authErrorMessage: "Please provide a valid email address.",
        });
        return;
      }
      if (isNullEmptyUndefinedString(this.state.username)) {
        this.setState({
          authInfoMessage: "",
          authErrorMessage: "Please provide a valid username.",
        });
        return;
      }
      if (isNullEmptyUndefinedString(this.state.userPassword)) {
        this.setState({
          authInfoMessage: "",
          authErrorMessage: "Please provide a valid password.",
        });
        return;
      }
      if (isNullEmptyUndefinedString(this.state.userConfirmPassword)) {
        this.setState({
          authInfoMessage: "",
          authErrorMessage: "Please provide a valid password.",
        });
        return;
      }
      if (this.state.userConfirmPassword != this.state.userPassword) {
        this.setState({
          authInfoMessage: "",
          authErrorMessage: "Password didn't match.",
        });
        return;
      }

      const userDetails = {
        emailAddress: this.state.userEmailAddress,
        username: this.state.username,
        password: this.state.userPassword,
      };

      // await this.props.submit(userDetails);
      const userAttributes: Array<CognitoUserAttribute> =
        new Array<CognitoUserAttribute>();
      const validationAttribute: Array<CognitoUserAttribute> =
        new Array<CognitoUserAttribute>();

      userAttributes.push(
        new CognitoUserAttribute({
          Name: "email",
          Value: userDetails.emailAddress,
        }),
      );
      userAttributes.push(
        new CognitoUserAttribute({
          Name: "email",
          Value: userDetails.emailAddress,
        }),
      );

      this.props.cognitoHelper
        .signUp(
          userAttributes,
          validationAttribute,
          userDetails.username,
          userDetails.password,
        )
        .then((res: any) => {
          this.dismiss();
        })
        .catch((error: any) => {
          this.setState({
            authInfoMessage: "",
            authErrorMessage: error.message,
          });
        });

      this.resetAuthInfo();
    } catch (e: any) {
      this.setState({
        authInfoMessage: "",
        authErrorMessage: e.message,
      });
    }
  }

  dismiss() {
    this.props.dismiss();
    this.resetAuthInfo();
  }

  resetAuthInfo() {
    this.setState({
      authInfo: "",
      authErrorMessage: "",
      authInfoMessage: "",
    });
  }

  componentWillUnmount() {
    this.resetAuthInfo();
  }

  render() {
    // Action
    return (
      <GenericModal
        key="register-new-user"
        isVisible={this.props.isVisible}
        maxWidth={600}
        header={<h4 className="input-label">Register new user</h4>}
        body={
          <div className="modal-body">
            <MDBValidation className="row g-3 needs-validation">
              <MDBValidationItem feedback="" invalid>
                <MDBInput
                  label="Email address"
                  id="register-email"
                  type="email"
                  value={this.state.userEmailAddress}
                  required
                  onChange={(event) =>
                    this.setState({ userEmailAddress: event.target.value })
                  }
                />
              </MDBValidationItem>
              <MDBValidationItem feedback="" invalid>
                <MDBInput
                  label="Unique username"
                  id="register-username"
                  type="text"
                  value={this.state.username}
                  required
                  onChange={(event) =>
                    this.setState({ username: event.target.value })
                  }
                />
              </MDBValidationItem>
              <MDBValidationItem feedback="" invalid>
                <MDBInput
                  label="Password"
                  id="register-password"
                  type="password"
                  value={this.state.userPassword}
                  required
                  onChange={(event) =>
                    this.setState({ userPassword: event.target.value })
                  }
                />
              </MDBValidationItem>
              <MDBValidationItem feedback="" invalid>
                <MDBInput
                  label="Confirm Password"
                  id="register-confirm-password"
                  type="password"
                  value={this.state.userConfirmPassword}
                  required
                  onChange={(event) =>
                    this.setState({ userConfirmPassword: event.target.value })
                  }
                />
              </MDBValidationItem>
            </MDBValidation>
            <br />
            <div className="p-b-10">
              <p>Please follow below password requirements:</p>
              <ul>
                <li>Password minimum length 8 characters</li>
                <li>Password should contains at least 1 number</li>
                <li>
                  Password should contains at least 1 special character. The
                  following characters count as special characters like &, # or
                  *
                </li>
                <li>Password should contains at least 1 uppercase letter</li>
                <li>Password should contains at least 1 lowercase letter</li>
              </ul>
            </div>
            <div style={{ color: "red" }}>{this.state.authErrorMessage}</div>
          </div>
        }
        footer={[
          <MDBBtn type="submit" className="me-1" onClick={(e) => this.submit()}>
            Submit
          </MDBBtn>,
          <MDBBtn
            color="danger"
            className="me-1"
            onClick={(e) => this.dismiss()}
          >
            Cancel
          </MDBBtn>,
        ]}
      />
    );
  }
}

export default RegisterAuthModal;
