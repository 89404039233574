import * as React from "react";

export interface ICountDownTimer {
  message: string;
  initTimer: number;
  timerStatus: boolean;
  changeShowResendCode: (isShow: boolean) => any;
  changeTimerStatus: (status: boolean) => any;
}

export class CountDownTimer extends React.Component<ICountDownTimer, any> {
  timer: NodeJS.Timer | null;
  constructor(props: any) {
    super(props);
    this.state = {
      time: {},
      seconds: 0,
    };
    this.timer = null;

    this.countDown = this.countDown.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.initiateTimer = this.initiateTimer.bind(this);
    this.secondsToTime = this.secondsToTime.bind(this);
    this.clearTimer = this.clearTimer.bind(this);
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    if (seconds <= 0) {
      this.clearTimer();
    }

    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
  }

  startTimer() {
    if (this.timer == null && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  initiateTimer() {
    this.clearTimer();
    const { initTimer } = this.props;
    let timeLeft = this.secondsToTime(initTimer);

    this.setState(
      {
        time: timeLeft,
        seconds: initTimer,
      },
      () => {
        this.startTimer();
        this.props.changeShowResendCode(false);
      },
    );
  }

  clearTimer() {
    if (this.timer) {
      this.setState(
        {
          time: this.secondsToTime(0),
          seconds: 0,
        },
        () => {
          this.props.changeShowResendCode(true);
          this.props.changeTimerStatus(false);
          clearInterval(this.timer!);
          this.timer = null;
        },
      );
    }
  }

  secondsToTime(secs: any) {
    let hours = Math.floor(secs / (60 * 60));

    let devisorForMinutes = secs % (60 * 60);
    let minutes = Math.floor(devisorForMinutes / 60);

    let devisorForSeconds = devisorForMinutes % 60;
    let seconds = Math.ceil(devisorForSeconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.timerStatus !== this.props.timerStatus) {
      if (this.props.timerStatus) {
        this.initiateTimer();
      } else {
        this.clearTimer();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer!);
  }

  getTimeString(num: { toString: () => string }) {
    return num ? num.toString().padStart(2, "0") : "00";
  }

  render() {
    const { message } = this.props;
    const sec = this.getTimeString(this.state.time.s);
    const min = this.getTimeString(this.state.time.m);

    return (
      <div>
        {message}{" "}
        <b>
          {min}:{sec}
        </b>
      </div>
    );
  }
}
