import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { inject, observer } from "mobx-react";
import React from "react";
import { AWSError } from "aws-sdk";
import {
  EXPIRED_MFA_ERROR_MESSAGE,
  FAIL_TO_SEND_CODE_ERROR_MESSAGE,
  INVALID_MFA_ERROR_MESSAGE,
  INVALID_VERIFICATION_CODE_ERROR_MESSAGE,
  PASSWORD_ATTEMPT_EXCEEDED_ERROR_MESSAGE,
  SIGN_IN_ERROR_HEADER,
  UNVERIFIED_EMAIL_ERROR_MESSAGE,
  USER_NOT_FOUND_ERROR_MESSAGE,
  USER_PENDING_APPROVAL_ERROR_MESSAGE,
  VOICE_CALL_ATTEMPT_EXCEEDED_ERROR_MESSAGE,
} from "../../constants/message";
import { isNullEmptyUndefinedString } from "../../utils/validationUtils";
import { RouterAddress } from "../../constants/RouterAddress";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import LoginAuthModal from "./loginAuthModal";
import { SubmitButton } from "../general/submitButton";
import SendResetEmailModal from "./sendResetEmailModal";
import { UnsignInCognitoHelper } from "../../utils/unsignInCognitoHelper";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBFooter,
  MDBListGroup,
  MDBListGroupItem,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBInputGroup,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import ResetPasswordSuccessModal from "./resetPasswordSuccessModal";
import ResetPasswordModal from "./resetPasswordModal";
import RegisterAuthModal from "./registerNewUser";
// import ResetPasswordModal from "./resetPasswordModal";
// import ResetPasswordSuccessModal from "./resetPasswordSuccessModal";

interface ILoginComponentStateProps {
  emailAddressTxtField: string;
  passwordTxtField: string;
  displayAccountVerificationModal: boolean;
  verificationCodeTxtField: string;
  passwordResetEmailInputModal: boolean;
  registerInputModal: boolean;
  resetLinkEmailId: string;
  passwordSetNewPasswordModal: boolean;
  passwordAndVerificationCodeModal: boolean;
  newPasswordVerificationCode: string;
  newPasswordTxtField: string;
  signInErrorMessageHeader: string;
  signInErrorMessage: string;
  setNewPasswordError: string;
  forgotPasswordIncorrectEmailError: string;
  forgotPasswordVerificationFlowError: string;
  signInVerificationCodeErrorMessage: string;
  updateUserInfoModal: boolean;
  resetPasswordSuccessMessageModal: boolean;
  loading?: boolean;
  authLoading?: boolean;
  showSignInSection?: boolean;
}

type loginProps = any;

@inject("authStore", "cognitoHelper")
@observer
class Login extends React.Component<loginProps, ILoginComponentStateProps> {
  private cognitoUser: CognitoUser | undefined;

  private readonly PASSWORD_RESET_EXCEPTION: string[] = [
    "PasswordResetRequiredException",
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      emailAddressTxtField: "",
      passwordTxtField: "",
      displayAccountVerificationModal: false,
      verificationCodeTxtField: "",
      passwordResetEmailInputModal: false,
      registerInputModal: false,
      resetLinkEmailId: "",
      passwordSetNewPasswordModal: false,
      passwordAndVerificationCodeModal: false,
      newPasswordVerificationCode: "",
      newPasswordTxtField: "",
      signInErrorMessageHeader: "",
      signInErrorMessage: "",
      forgotPasswordIncorrectEmailError: "",
      setNewPasswordError: "",
      forgotPasswordVerificationFlowError: "",
      signInVerificationCodeErrorMessage: "",
      updateUserInfoModal: false,
      resetPasswordSuccessMessageModal: false,
      loading: false,
      authLoading: false,
      showSignInSection: true,
    };

    this.onSignInClick = this.onSignInClick.bind(this);
    this.onRegisterClick = this.onRegisterClick.bind(this);
    this.initiateAuth = this.initiateAuth.bind(this);
    this.answerCustomChallenge = this.answerCustomChallenge.bind(this);
    this.displayAccountVerificationModal =
      this.displayAccountVerificationModal.bind(this);
    this.displayPasswordResetEmailInputModal =
      this.displayPasswordResetEmailInputModal.bind(this);
    this.displayRegisterInputModal = this.displayRegisterInputModal.bind(this);
    this.displayPasswordSetNewPasswordModal =
      this.displayPasswordSetNewPasswordModal.bind(this);
    this.sendForgotPasswordEmail = this.sendForgotPasswordEmail.bind(this);
    this.setNewPassword = this.setNewPassword.bind(this);
    this.displayPasswordAndVerificationCodeModal =
      this.displayPasswordAndVerificationCodeModal.bind(this);
    this.confirmPasswordAndVerificationCode =
      this.confirmPasswordAndVerificationCode.bind(this);
    this.isValidInput = this.isValidInput.bind(this);
    this.setErrorMessageAndHeaderForSignIn =
      this.setErrorMessageAndHeaderForSignIn.bind(this);
    this.setMessageForForgotPasswordIncorrectEmail =
      this.setMessageForForgotPasswordIncorrectEmail.bind(this);
    this.setMessageForForgotPasswordVerificationFlowError =
      this.setMessageForForgotPasswordVerificationFlowError.bind(this);
    this.setErrorMessageForSignInVerificationCode =
      this.setErrorMessageForSignInVerificationCode.bind(this);
    this.setErrorMessageForNewPasswordModal =
      this.setErrorMessageForNewPasswordModal.bind(this);
    this.resendVerificationLink = this.resendVerificationLink.bind(this);
    this.onDismissPasswordAndVerificationModal =
      this.onDismissPasswordAndVerificationModal.bind(this);
    this.onDismissForgotPasswordModal =
      this.onDismissForgotPasswordModal.bind(this);
    this.onDismissSetNewPasswordModal =
      this.onDismissSetNewPasswordModal.bind(this);
    this.onDismissResetPasswordSuccessMessageModal =
      this.onDismissResetPasswordSuccessMessageModal.bind(this);
    this.onDismissUpdateUserInfoModal =
      this.onDismissUpdateUserInfoModal.bind(this);
    this.dismissAccountVerificationModal =
      this.dismissAccountVerificationModal.bind(this);
    this.setAuthLoadingOn = this.setAuthLoadingOn.bind(this);
    this.setAuthLoadingOff = this.setAuthLoadingOff.bind(this);
    this.signInSectionHTML = this.signInSectionHTML.bind(this);
  }

  setErrorMessageAndHeaderForSignIn(error: any) {
    switch (error.code) {
      case "UserNotFoundException":
        this.setState({
          signInErrorMessage: USER_NOT_FOUND_ERROR_MESSAGE,
          signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
        });
        return;
      case "UserNotConfirmedException":
        this.setState({
          signInErrorMessage: UNVERIFIED_EMAIL_ERROR_MESSAGE,
          signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
        });
        return;
      case "NotAuthorizedException":
        if (error.message === "User is disabled.") {
          this.setState({
            signInErrorMessage: USER_PENDING_APPROVAL_ERROR_MESSAGE,
            signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
          });
        } else if (error.message === "Password attempts exceeded") {
          this.setState({
            signInErrorMessage: PASSWORD_ATTEMPT_EXCEEDED_ERROR_MESSAGE,
            signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
          });
        } else {
          this.setState({
            signInErrorMessage: error.message,
            signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
          });
        }
        return;
      default:
        // error.code: "UserLambdaValidationException"
        if (error.message.includes("TooManyRequestsException")) {
          this.setState({
            signInErrorMessage: VOICE_CALL_ATTEMPT_EXCEEDED_ERROR_MESSAGE,
            signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
          });
        } else if (error.message.includes("Failed to send login code")) {
          this.setState({
            signInErrorMessage: FAIL_TO_SEND_CODE_ERROR_MESSAGE,
            signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
          });
        } else {
          this.setState({
            signInErrorMessage: error.message,
            signInErrorMessageHeader: SIGN_IN_ERROR_HEADER,
          });
        }
    }
  }

  setErrorMessageForSignInVerificationCode(error: any) {
    switch (error.code) {
      case "UserLambdaValidationException":
        this.setState({
          signInVerificationCodeErrorMessage:
            "Internal error occured. Please close the window and retry sign-in.",
        });
        return;
      case "NotAuthorizedException":
        this.setState({
          signInVerificationCodeErrorMessage:
            "Session has expired. Please close the window and retry sign-in.",
        });
        return;
      default:
        this.setState({
          signInVerificationCodeErrorMessage:
            "Please provide a valid verification code.",
        });
        return;
    }
  }

  setMessageForForgotPasswordIncorrectEmail(error: any) {
    switch (error.code) {
      case "UserNotFoundException":
        this.setState({
          forgotPasswordIncorrectEmailError: USER_NOT_FOUND_ERROR_MESSAGE,
        });
        return;
      case "InvalidParameterException":
        if (
          error.message ===
          "Cannot reset password for the user as there is " +
            "no registered/verified email or phone_number"
        ) {
          this.setState({
            forgotPasswordIncorrectEmailError: UNVERIFIED_EMAIL_ERROR_MESSAGE,
          });
        } else {
          this.setState({
            forgotPasswordIncorrectEmailError: error.message,
          });
        }
        return;
      default:
        this.setState({
          forgotPasswordIncorrectEmailError: error.message,
        });
    }
  }

  setErrorMessageForNewPasswordModal(error: Error) {
    this.setState({
      setNewPasswordError: error.message,
    });
  }

  setMessageForForgotPasswordVerificationFlowError(error: any) {
    this.setState({
      forgotPasswordVerificationFlowError: error.message,
    });
  }

  initiateAuth(userName: string, password: string) {
    this.setState({
      signInErrorMessage: "",
      signInErrorMessageHeader: "",
    });

    const authenticationDetails: AuthenticationDetails =
      new AuthenticationDetails({
        Username: userName,
        Password: password,
      });

    this.cognitoUser = new CognitoUser({
      Username: userName,
      Pool: this.props.authStore.userPoolProps,
    });

    this.cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
    const currentInstance = this;

    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (session: CognitoUserSession) {
        currentInstance.setState({ loading: false });
      },

      onFailure: function (error: AWSError) {
        console.log(error);
        if (currentInstance.PASSWORD_RESET_EXCEPTION.indexOf(error.code) >= 0) {
          currentInstance.setState({
            resetLinkEmailId: userName,
          });
          currentInstance.displayPasswordResetEmailInputModal();
        } else {
          currentInstance.setErrorMessageAndHeaderForSignIn(error);
        }
        currentInstance.setState({ loading: false });
      },

      newPasswordRequired: function (userAttributes, requiredAttributes) {
        currentInstance.displayPasswordSetNewPasswordModal();
        currentInstance.setState({ loading: false });
      },
      customChallenge: function (param: any) {
        currentInstance.displayAccountVerificationModal();
        currentInstance.setState({ loading: false });
      },
      mfaRequired: function (challengeName: any, challengeParameters: any) {
        currentInstance.displayAccountVerificationModal();
        currentInstance.setState({ loading: false });
      },
    });
  }

  displayPasswordResetEmailInputModal() {
    this.setState({
      passwordResetEmailInputModal: true,
    });
  }

  displayRegisterInputModal() {
    this.setState({
      registerInputModal: true,
    });
  }

  dismissRegisterInputModal() {
    this.setState({
      registerInputModal: false,
    });
  }

  displayPasswordSetNewPasswordModal() {
    this.setState({
      passwordSetNewPasswordModal: true,
    });
  }

  displayAccountVerificationModal() {
    this.setState({
      displayAccountVerificationModal: true,
    });
  }

  dismissAccountVerificationModal() {
    this.setState({
      displayAccountVerificationModal: false,
      signInVerificationCodeErrorMessage: "",
    });
  }

  sendForgotPasswordEmail(email: string): Promise<any> {
    email = email.toLowerCase();
    let cognitoUser: CognitoUser = new CognitoUser({
      Username: email,
      Pool: this.props.authStore.userPoolProps,
    });

    const currentInstance = this;
    this.setState({ resetLinkEmailId: email });
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          resolve(null);
        },
        onFailure: function (error) {
          reject();
          console.log(error);
          currentInstance.setMessageForForgotPasswordIncorrectEmail(error);
        },

        inputVerificationCode: function (data) {
          currentInstance.displayPasswordAndVerificationCodeModal();
          resolve(null);
        },
      });
    });
  }

  setNewPassword(newPassword: string) {
    return new Promise((resolve, reject) => {
      this.cognitoUser!.completeNewPasswordChallenge(newPassword, null, {
        onSuccess: (session: CognitoUserSession) => {
          this.onDismissSetNewPasswordModal();
          this.setState({
            resetPasswordSuccessMessageModal: true,
          });
          resolve(null);
        },
        onFailure: (err: any) => {
          reject(err);
        },
      });
    });
  }

  displayPasswordAndVerificationCodeModal() {
    this.setState({
      passwordAndVerificationCodeModal: true,
      passwordResetEmailInputModal: false,
      registerInputModal: false,
    });
  }

  confirmPasswordAndVerificationCode(
    password: string,
    authCode?: string,
  ): Promise<any> {
    let cognitoUser: CognitoUser = new CognitoUser({
      Username: this.state.resetLinkEmailId,
      Pool: this.props.authStore.userPoolProps,
    });

    const currentInstance = this;
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(authCode!, password, {
        onSuccess() {
          currentInstance.setState({
            passwordAndVerificationCodeModal: false,
            resetLinkEmailId: "",
            verificationCodeTxtField: "",
            newPasswordTxtField: "",
            resetPasswordSuccessMessageModal: true,
          });
          resolve(null);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  }

  setAuthLoadingOn() {
    this.setState({
      authLoading: true,
    });
  }

  setAuthLoadingOff() {
    this.setState({
      authLoading: false,
    });
  }

  async answerCustomChallenge(code: String): Promise<boolean> {
    // const navigate = useNavigate();
    this.setState({
      signInVerificationCodeErrorMessage: "",
    });
    this.setAuthLoadingOn();
    return await this.doOTPVerificationViaCustomSolution(code);
  }

  doOTPVerificationViaCustomSolution(code: String): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.cognitoUser!.sendCustomChallengeAnswer(code, {
        onSuccess: async (
          session: CognitoUserSession,
          userConfirmationNecessary?: boolean,
        ) => {
          this.setAuthLoadingOff();
          this.props.authStore.setUserProperties(
            session,
            this.cognitoUser!.getUsername(),
          );
          window.location.href = RouterAddress.DASHBOARD_PROVIDER;
        },
        onFailure: (error: any) => {
          this.setAuthLoadingOff();
          this.setErrorMessageForSignInVerificationCode(error);

          return reject(false);
        },
      });
    });
  }

  isValidInput() {
    return (
      !isNullEmptyUndefinedString(this.state.emailAddressTxtField) &&
      !isNullEmptyUndefinedString(this.state.passwordTxtField)
    );
  }

  onRegisterClick(userDetails: any) {}

  onSignInClick() {
    if (!this.isValidInput()) {
      return;
    }

    this.setState({ loading: true });

    this.initiateAuth(
      this.state.emailAddressTxtField.toLowerCase(),
      this.state.passwordTxtField,
    );
  }

  resendVerificationLink() {
    const { t } = this.props;
    this.onSignInClick();
    this.setState({
      signInVerificationCodeErrorMessage:
        "New code sent. Please use the most recent code to verify account.",
    });
  }

  onDismissPasswordAndVerificationModal() {
    this.setState({
      passwordAndVerificationCodeModal: false,
      resetLinkEmailId: "",
      forgotPasswordIncorrectEmailError: "",
      forgotPasswordVerificationFlowError: "",
      newPasswordVerificationCode: "",
      newPasswordTxtField: "",
    });
  }

  onDismissForgotPasswordModal() {
    this.setState({
      passwordResetEmailInputModal: false,
      forgotPasswordIncorrectEmailError: "",
      resetLinkEmailId: "",
    });
  }

  onDismissSetNewPasswordModal() {
    this.setState({
      passwordSetNewPasswordModal: false,
      setNewPasswordError: "",
      newPasswordTxtField: "",
    });
  }

  onDismissUpdateUserInfoModal() {
    this.setState({
      updateUserInfoModal: false,
    });
  }

  onDismissResetPasswordSuccessMessageModal() {
    this.setState({
      resetPasswordSuccessMessageModal: false,
      emailAddressTxtField: "",
      passwordTxtField: "",
      resetLinkEmailId: "",
      newPasswordVerificationCode: "",
      newPasswordTxtField: "",
    });
  }

  signInSectionHTML() {
    return (
      <div>
        
      </div>
    );
  }

  render() {
    const { displayAccountVerificationModal } = this.state;

    return (
      <div>
        <div className="app-banner">
          This website is in development. Please do not use this for business management.
        </div>
        <div style={{margin: "1em"}}>
          <MDBRow>
            <MDBCol>
              <div className="card" style={{textAlign: "center"}}>
                <div className="card-body py-5">
                  <h2 className="display-5">
                    Welcome to <br />
                    <span className="text-primary">Adult Family Home Hub</span>
                  </h2>

                  <h4 style={{color: "orange"}}>One place for managing your business content</h4>
                  <p>&nbsp;</p>
                  <MDBCarousel showControls dark interval={5000}>
                    <MDBCarouselItem itemId={1}>
                      <h4>Home Website</h4>
                      <MDBListGroup style={{ minWidth: "22rem" }} light>
                        <MDBListGroupItem className="px-3">
                          Create your AFH website
                        </MDBListGroupItem>
                        <MDBListGroupItem className="px-3">
                          Customize the website content by self
                        </MDBListGroupItem>
                        <MDBListGroupItem className="px-3">
                          Updates to website will be in seconds
                        </MDBListGroupItem>
                      </MDBListGroup>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId={2}>
                      <h4>Manage Residents</h4>
                      <MDBListGroup style={{ minWidth: "22rem" }} light>
                        <MDBListGroupItem className="px-3">
                          Add/Remove resident profile
                        </MDBListGroupItem>
                        <MDBListGroupItem className="px-3">
                          View all your residents
                        </MDBListGroupItem>
                        <MDBListGroupItem className="px-3">
                          Manage the resident documents
                        </MDBListGroupItem>
                      </MDBListGroup>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId={2}>
                      <h4>Manage Caregivers</h4>
                      <MDBListGroup style={{ minWidth: "22rem" }} light>
                        <MDBListGroupItem className="px-3">
                          Add/Remove Caregiver profile
                        </MDBListGroupItem>
                        <MDBListGroupItem className="px-3">
                          View all your caregivers
                        </MDBListGroupItem>
                        <MDBListGroupItem className="px-3">
                          Provide caregiver feedbacks
                        </MDBListGroupItem>
                        <MDBListGroupItem className="px-3">
                        Manage the caregiver documents
                        </MDBListGroupItem>
                      </MDBListGroup>
                    </MDBCarouselItem>
                  </MDBCarousel>
                </div>
              </div>
            </MDBCol>
            <MDBCol>
              <div className="card" style={{minWidth: "20em"}}>
                <div className="card-body py-5 px-md-5">
                  <h2>Sign-In</h2>
                  <MDBValidation className="row g-3 needs-validation">
                    <MDBValidationItem feedback="" invalid>
                      <MDBInput
                        label="Email address"
                        id="sign-in-email"
                        type="email"
                        value={this.state.emailAddressTxtField}
                        required
                        onChange={(event) =>
                          this.setState({ emailAddressTxtField: event.target.value })
                        }
                      />
                    </MDBValidationItem>
                    <MDBValidationItem feedback="" invalid>
                      <MDBInput
                        label="Password"
                        id="sign-in-password"
                        type="password"
                        value={this.state.passwordTxtField}
                        required
                        onChange={(event) =>
                          this.setState({ passwordTxtField: event.target.value })
                        }
                      />
                    </MDBValidationItem>
                    <div>
                      <MDBBtn type="submit" onClick={(e) => this.onSignInClick()}>
                        Sign-In
                      </MDBBtn>

                      <MDBBtn
                        type="submit"
                        className="float-end"
                        onClick={this.displayPasswordResetEmailInputModal}
                        color="tertiary"
                      >
                        Forgot Password?
                      </MDBBtn>
                    </div>
                    <div className="d-inline text-center">
                      New to Hub?
                      <br />
                      <MDBBtn
                        type="submit"
                        className="col-md-4"
                        color="info"
                        onClick={this.displayRegisterInputModal}
                      >
                        Join the Hub
                      </MDBBtn>
                    </div>
                    <div style={{ color: "red" }}>
                      {this.state.signInErrorMessage}
                    </div>
                  </MDBValidation>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        <ResetPasswordSuccessModal
          isVisible={this.state.resetPasswordSuccessMessageModal}
          dismiss={this.onDismissResetPasswordSuccessMessageModal}
        />
        {/*for forget password*/}
        <ResetPasswordModal
          isVisible={this.state.passwordAndVerificationCodeModal}
          showAuthCodeField={true}
          submitResetPassword={this.confirmPasswordAndVerificationCode}
          dismiss={this.onDismissPasswordAndVerificationModal}
        />
        {/*for temp user to update their password*/}
        <ResetPasswordModal
          isVisible={this.state.passwordSetNewPasswordModal}
          showAuthCodeField={false}
          submitResetPassword={this.setNewPassword}
          dismiss={this.displayPasswordSetNewPasswordModal}
        />
        <LoginAuthModal
          authModal={displayAccountVerificationModal}
          submitAuth={(code: string) => this.answerCustomChallenge(code)}
          anotherAuthCode={async () => this.resendVerificationLink()}
          dismiss={() => this.dismissAccountVerificationModal()}
          authLoading={this.state.authLoading!}
          errorMessage={this.state.signInVerificationCodeErrorMessage}
        />
        <SendResetEmailModal
          isVisible={this.state.passwordResetEmailInputModal}
          submitEmail={(email: string) => this.sendForgotPasswordEmail(email)}
          dismiss={this.onDismissForgotPasswordModal}
        />
        <RegisterAuthModal
          isVisible={this.state.registerInputModal}
          cognitoHelper={this.props.cognitoHelper}
          submit={(userDetails: object) => this.onRegisterClick(userDetails)}
          dismiss={() => this.dismissRegisterInputModal()}
        />
      </div>
    );
  }
}

export default Login;
