export const SIGN_IN_ERROR_HEADER: string = "errorMessage.signInErrorHeader";
export const CREATE_STANDARD_REQUEST_SUCCESS_HEADER: string =
  "errorMessage.createStandardRequestSuccessHeader";
export const CREATE_STANDARD_REQUEST_INSTRUCTION_HEADER: string =
  "errorMessage.createStandardRequestInstructionHeader";
export const CREATE_EMERGENCY_REQUEST_SUCCESS_HEADER: string =
  "errorMessage.createEmergencyRequestSuccessHeader";
export const CREATE_EMERGENCY_REQUEST_INSTRUCTION_HEADER: string =
  "errorMessage.createEmergencyRequestInstructionHeader";
export const CHANGE_PASSWORD_SUCCESS_HEADER: string =
  "errorMessage.changePasswordSuccessHeader";
export const SEND_RESET_PASSWORD_LINK_HEADER: string =
  "errorMessage.sendResetPasswordLinkHeader";
export const RESET_PASSWORD_HEADER: string = "Reset Your Password";
export const SET_NEW_PASSWORD_HEADER: string =
  "errorMessage.setNewPasswordHeader";
export const UPDATE_USER_INFO_HEADER: string =
  "errorMessage.updateUserInfoHeader";
export const SEND_VERIFICATION_CODE_HEADER: string =
  "errorMessage.sendVerificationCodeHeader";
export const REGISTER_ACCOUNT_SUCCESS_HEADER: string =
  "errorMessage.registerAccountSuccessHeader";
export const USER_NOT_FOUND_ERROR_MESSAGE: string =
  "errorMessage.userNotFoundErrorMessage";
export const USER_PENDING_APPROVAL_ERROR_MESSAGE: string =
  "errorMessage.userPendingApprovalErrorMessage";
export const INVALID_EMAIL_ERROR_MESSAGE: string =
  "errorMessage.invalidEmailErrorMessage";
export const INVALID_PASSWORD_ERROR_MESSAGE: string =
  "Please provide a valid password.";
export const INVALID_CONFIRM_PASSWORD_ERROR_MESSAGE: string =
  "Confirm Password Must Match Password.";
export const PASSWORD_ATTEMPT_EXCEEDED_ERROR_MESSAGE: string =
  "errorMessage.passwordAttemptExceededErrorMessage";
export const VOICE_CALL_ATTEMPT_EXCEEDED_ERROR_MESSAGE: string =
  "errorMessage.voiceCallAttemptExceededErrorMessage";
export const FAIL_TO_SEND_CODE_ERROR_MESSAGE: string =
  "errorMessage.failToSendCodeErrorMessage";
export const INVALID_VERIFICATION_CODE_ERROR_MESSAGE: string =
  "Please provide a valid verification code.";
export const UNVERIFIED_EMAIL_ERROR_MESSAGE: string =
  "errorMessage.unverifiedEmailErrorMessage";
export const INVALID_MFA_ERROR_MESSAGE: string =
  "errorMessage.invalidMfaErrorMessage";
export const EXPIRED_MFA_ERROR_MESSAGE: string =
  "errorMessage.expiredMfaErrorMessage";
export const SET_NEW_PASSWORD_MESSAGE: string =
  "errorMessage.setNewPasswordMessage";
export const UPDATE_USER_INFO_MESSAGE: string =
  "errorMessage.updateUserInfoMessage";
export const CREATE_STANDARD_REQUEST_SUCCESS_MESSAGE: string =
  "errorMessage.createStandardRequestSuccessMessage";
export const CREATE_EMERGENCY_REQUEST_SUCCESS_MESSAGE: string =
  "errorMessage.createEmergencyRequestSuccessMessage";
export const CREATE_STANDARD_REQUEST_INSTRUCTION_MESSAGE: string =
  "errorMessage.createStandardRequestInstructionMessage";
export const CREATE_EMERGENCY_REQUEST_INSTRUCTION_MESSAGE: string =
  "errorMessage.createEmergencyRequestInstructionMessage";
export const CREATE_EMERGENCY_REQUEST_DOCUMENT_HYPERLINK: string =
  "errorMessage.createEmergencyRequestDocumentHyperlink";
export const CHANGE_PASSWORD_SUCCESS_MESSAGE: string =
  "errorMessage.changePasswordSuccessMessage";
export const SEND_RESET_PASSWORD_LINK_MESSAGE: string =
  "errorMessage.sendResetPasswordLinkMessage";
export const SEND_VERIFICATION_CODE_MESSAGE: string =
  "errorMessage.sendVerificationCodeMessage";
export const SEND_VERIFICATION_CODE_SUCCESS_MESSAGE: string =
  "errorMessage.sendVerificationCodeSuccessMessage";
export const SEND_VERIFICATION_CODE_FAIL_MESSAGE: string =
  "errorMessage.sendVerificationCodeFailMessage";
export const REGISTER_ACCOUNT_SUCCESS_MESSAGE: string =
  "errorMessage.registerAccountSuccessMessage";
export const FORM_VALIDATION_ERROR_HEADER: string =
  "errorMessage.formValidationErrorHeader";
export const CREATE_REQUEST_ERROR_HEADER: string =
  "errorMessage.createRequestErrorHeader";
export const FORM_VALIDATION_ERROR_MESSAGE: string =
  "errorMessage.formValidationErrorMessage";
export const CREATE_REQUEST_ERROR_MESSAGE: string =
  "errorMessage.createRequestErrorMessage";
export const CREATE_EMERGENCY_REQUEST_ERROR_MESSAGE: string =
  "errorMessage.createEmergencyRequestErrorMessage";
export const UPDATE_USER_INFO_MESSAGE_SUCCESS: string =
  "errorMessage.updateUserInfoMessageSuccess";
export const UPLOAD_DOCUMENT_ERROR_MESSAGE: string =
  "errorMessage.uploadDocumentErrorMessage";
export const UPLOAD_DOCUMENT_ERROR_MESSAGE_HEADER: string =
  "errorMessage.uploadDocumentErrorMessageHeader";
export const INCORRECT_CAPTCHA_ERROR_MESSAGE: string =
  "errorMessage.incorrectCaptchaErrorMessage";
export const INVALID_PHONE_NUMBER_MESSAGE: string =
  "errorMessage.invalidPhoneNumberMessage";
export const PHONE_API_ERROR_MESSAGE: string =
  "errorMessage.phoneAPIErrorMessage";
export const NEED_PHONE_VALIDATION_MESSAGE: string =
  "errorMessage.needPhoneValidationMessage";
export const INCORRECT_ZIP_CODE_ERROR_MESSAGE: string =
  "errorMessage.incorrectZipCodeErrorMessage";
export const CREATE_NON_ER_CASE_ERROR_MESSAGE: string =
  "errorMessage.createNonErCaseErrorMessage";
export const GET_CASE_DETAIL_LIST_ERROR_MESSAGE: string =
  "errorMessage.getCaseDetailListErrorMessage";
export const GET_CASE_DETAIL_ERROR_MESSAGE: string =
  "errorMessage.getCaseDetailErrorMessage";
export const GET_CORRESPONDENCE_ERROR_MESSAGE: string =
  "errorMessage.getCorrespondenceErrorMessage";
export const CREATE_CORRESPONDENCE_ERROR_MESSAGE: string =
  "errorMessage.createCorrespondenceErrorMessage";
export const GET_DOCUMENT_FROM_AMAZON_ERROR_MESSAGE: string =
  "errorMessage.getDocumentFromAmazonErrorMessage";
export const DOWNLOAD_DOCUMENT_ERROR_MESSAGE: string =
  "errorMessage.downloadDocumentErrorMessage";
export const UNSUPPORT_DOCUMENT_TYPE_ERROR_MESSAGE: string =
  "errorMessage.unsupportDocumentTypeErrorMessage";
export const PASSWORD_LENGTH_CHECK: string =
  "Password need to be at least 8 character";
export const PASSWORD_NUMBER_CHECK: string = "Password need to have number";
export const PASSWORD_SPECIAL_CHECK: string =
  "Password need to have special character } ^$*.[]{}()?-\"!@#%&/\\,>\\<':,|_~`";
export const PASSWORD_UPPERCASE_CHECK: string =
  "Password need to have uppercase";
export const PASSWORD_LOWERCASE_CHECK: string =
  "Password need to have lowercase";
export const INVALID_NOT_A_NUMBER: string = "Please provide a number";
export const INVALID_EMAIL_DOMAIN_MESSAGE: string =
  "errorMessage.invalidEmailDomainMessage";
export const EMAIL_DOMAIN_API_ERROR_MESSAGE: string =
  "errorMessage.invalidEmailDomainAPIMessage";
