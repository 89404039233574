export class CaregiverProfile {
    id: string | undefined;
    rangeId: string | undefined;

    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    phoneNumber: string | undefined;

    addressLine1: string | undefined;
    addressLine2: string | undefined;
    city: string | undefined;
    state: string = 'WA';
    country: string = 'US';
    zipcode: string | undefined;

    active: boolean = true;
    createdAt: string | undefined;
    createdBy: string | undefined;
    modifiedAt: string | undefined;
    modifiedBy: string | undefined;
}