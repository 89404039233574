import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { COGNITO_APP_POOL_CONFIG } from "../constants/apiGatewayConstants";

export class UnsignInCognitoHelper {
  private readonly userPool: CognitoUserPool;

  constructor() {
    const poolConfig = COGNITO_APP_POOL_CONFIG.DEV;
    this.userPool = new CognitoUserPool({
      UserPoolId: poolConfig.UserPoolId,
      ClientId: poolConfig.ClientId,
    });
  }

  /**
   * Sign In not required Action
   * @param userAttribute
   * @param validationAttributes
   * @param email
   * @param password
   */
  public signUp(
    userAttribute: Array<CognitoUserAttribute>,
    validationAttributes: Array<CognitoUserAttribute>,
    email: string,
    password: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        email,
        password,
        userAttribute,
        validationAttributes,
        (err: any, result?) => {
          if (err) {
            reject(err);
          }
          // @ts-ignore
          resolve(undefined);
        },
      );
    });
  }

  /**
   * Sign In not required Action
   * @param userEmail
   */
  public getConfirmationEmail(userEmail: string): Promise<any> {
    const cognitoUser: CognitoUser = new CognitoUser({
      Username: userEmail,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err.message);
        }
        // @ts-ignore
        resolve(undefined);
      });
    });
  }

  /**
   * Sign IN not Required Action
   * @param userName
   * @param authCode
   */
  public confirmRegistration(userName: string, authCode: string) {
    const currentUser = new CognitoUser({
      Username: userName,
      Pool: this.userPool,
    });
    return new Promise((resolve, reject) => {
      currentUser.confirmRegistration(authCode, false, (err, result) => {
        if (err) {
          reject(err);
        }
        // @ts-ignore
        resolve(undefined);
      });
    });
  }
}
