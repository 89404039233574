import React, { createContext, useContext } from "react";

export class AuthContextProvider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      cognitoUser: null,
      loading: true,
    };
  }

  async componentDidMount(): Promise<void> {
    const user = this.props.userPoolProps.getCurrentUser();
    this.setState({
      cognitoUser: user,
      loading: false,
    });
  }

  render() {
    return (
      <AuthContext.Provider value={{ ...this.props, ...this.state }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const AuthContext = createContext<any>(null);
export const useAuthContext = () => useContext(AuthContext);
