import * as React from "react";
import {
  isNullEmptyUndefinedString,
  textContainLowerCaseCharacter,
  textContainNumber,
  textContainSpecialCharacter,
  textContainUpperCaseCharacter,
  textIsLongEnough,
} from "../../utils/validationUtils";
import {
  INVALID_CONFIRM_PASSWORD_ERROR_MESSAGE,
  INVALID_PASSWORD_ERROR_MESSAGE,
  INVALID_VERIFICATION_CODE_ERROR_MESSAGE,
  PASSWORD_LENGTH_CHECK,
  PASSWORD_LOWERCASE_CHECK,
  PASSWORD_NUMBER_CHECK,
  PASSWORD_SPECIAL_CHECK,
  PASSWORD_UPPERCASE_CHECK,
  RESET_PASSWORD_HEADER,
} from "../../constants/message";
import { GenericModal } from "../general/genericModal";
import { SubmitButton } from "../general/submitButton";
import { FORM_FIELD_VALIDATION_STATUS } from "../../constants/enum";
import {
  MDBBtn,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";

export interface IResetPassword {
  isVisible: boolean;
  showAuthCodeField: boolean;
  submitResetPassword: (password: string, authCode?: string) => Promise<any>;
  dismiss: () => any;
}

class ResetPasswordModal extends React.Component<IResetPassword, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      authCode: "",
      password: "",
      confirmPassword: "",
      submitLoading: false,
      passwordStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
      authCodeStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
      confirmPasswordStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
      submitErrorMessage: "",
      authCodeErrorMessage: "",
      passwordValidationErrorMessage: "",
      confirmPasswordValidationErrorMessage: "",
      ruleListStatus: [
        {
          message: PASSWORD_LENGTH_CHECK,
          validStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
        },
        {
          message: PASSWORD_NUMBER_CHECK,
          validStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
        },
        {
          message: PASSWORD_SPECIAL_CHECK,
          validStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
        },
        {
          message: PASSWORD_UPPERCASE_CHECK,
          validStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
        },
        {
          message: PASSWORD_LOWERCASE_CHECK,
          validStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
        },
      ],
    };
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeAuthCode = this.onChangeAuthCode.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.submit = this.submit.bind(this);
  }
  dismiss() {
    const { dismiss } = this.props;
    dismiss();
    this.resetModal();
  }
  onChangePassword(password: string) {
    let { ruleListStatus } = this.state;
    ruleListStatus[0].validStatus = textIsLongEnough(password, 8)
      ? FORM_FIELD_VALIDATION_STATUS.VALID
      : FORM_FIELD_VALIDATION_STATUS.NOT_VALID;
    ruleListStatus[1].validStatus = textContainNumber(password)
      ? FORM_FIELD_VALIDATION_STATUS.VALID
      : FORM_FIELD_VALIDATION_STATUS.NOT_VALID;
    ruleListStatus[2].validStatus = textContainSpecialCharacter(password)
      ? FORM_FIELD_VALIDATION_STATUS.VALID
      : FORM_FIELD_VALIDATION_STATUS.NOT_VALID;
    ruleListStatus[3].validStatus = textContainUpperCaseCharacter(password)
      ? FORM_FIELD_VALIDATION_STATUS.VALID
      : FORM_FIELD_VALIDATION_STATUS.NOT_VALID;
    ruleListStatus[4].validStatus = textContainLowerCaseCharacter(password)
      ? FORM_FIELD_VALIDATION_STATUS.VALID
      : FORM_FIELD_VALIDATION_STATUS.NOT_VALID;
    const passwordStatus =
      ruleListStatus.reduce((result: any, item: any) => {
        return (
          result && item.validStatus === FORM_FIELD_VALIDATION_STATUS.VALID
        );
      }, true) && !isNullEmptyUndefinedString(password)
        ? FORM_FIELD_VALIDATION_STATUS.VALID
        : FORM_FIELD_VALIDATION_STATUS.NOT_VALID;
    this.setState({
      password: password,
      passwordStatus: passwordStatus,
      ruleListStatus: ruleListStatus,
      passwordErrorMessage:
        passwordStatus === FORM_FIELD_VALIDATION_STATUS.NOT_VALID
          ? INVALID_PASSWORD_ERROR_MESSAGE
          : "",
    });
  }
  onChangeConfirmPassword(confirmPassword: string) {
    const { password } = this.state;
    const confirmPasswordStatus =
      isNullEmptyUndefinedString(confirmPassword) ||
      confirmPassword !== password
        ? FORM_FIELD_VALIDATION_STATUS.NOT_VALID
        : FORM_FIELD_VALIDATION_STATUS.VALID;
    const confirmPasswordErrorMessage =
      confirmPasswordStatus === FORM_FIELD_VALIDATION_STATUS.NOT_VALID
        ? INVALID_CONFIRM_PASSWORD_ERROR_MESSAGE
        : "";
    this.setState({
      confirmPassword: confirmPassword,
      confirmPasswordStatus: confirmPasswordStatus,
      confirmPasswordErrorMessage: confirmPasswordErrorMessage,
    });
  }
  onChangeAuthCode(authCode: string) {
    const authCodeStatus = isNullEmptyUndefinedString(authCode)
      ? FORM_FIELD_VALIDATION_STATUS.NOT_VALID
      : FORM_FIELD_VALIDATION_STATUS.VALID;
    const authCodeErrorMessage =
      authCodeStatus === FORM_FIELD_VALIDATION_STATUS.NOT_VALID
        ? INVALID_VERIFICATION_CODE_ERROR_MESSAGE
        : "";
    this.setState({
      authCode: authCode,
      authCodeStatus: authCodeStatus,
      authCodeErrorMessage: authCodeErrorMessage,
    });
  }
  async submit() {
    const { submitResetPassword, showAuthCodeField } = this.props;
    const {
      authCode,
      password,
      authCodeStatus,
      passwordStatus,
      confirmPasswordStatus,
    } = this.state;
    this.setState({ submitLoading: true });

    try {
      await submitResetPassword(password, authCode);
      this.resetModal();
    } catch (e: any) {
      this.setState({
        errorMessage: e.message,
      });
    } finally {
      this.setState({ submitLoading: false });
    }
  }
  resetModal() {
    const { ruleListStatus } = this.state;
    this.setState({
      authCode: "",
      password: "",
      confirmPassword: "",
      errorMessage: "",
      submitLoading: false,
      passwordStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
      authCodeStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
      confirmPasswordStatus: FORM_FIELD_VALIDATION_STATUS.INITIALIZE,
      passwordErrorMessage: "",
      authCodeErrorMessage: "",
      confirmPasswordErrorMessage: "",
      ruleListStatus: ruleListStatus.map((item: any) => {
        item.validStatus = FORM_FIELD_VALIDATION_STATUS.INITIALIZE;
        return item;
      }),
    });
  }
  render() {
    const { showAuthCodeField, isVisible } = this.props;
    const {
      submitLoading,
      errorMessage,
      ruleListStatus,
      password,
      passwordStatus,
      passwordErrorMessage,
      authCode,
      authCodeStatus,
      authCodeErrorMessage,
      confirmPassword,
      confirmPasswordStatus,
      confirmPasswordErrorMessage,
    } = this.state;

    return (
      <GenericModal
        key="reset-password-modal"
        isVisible={isVisible}
        header={<h3>Reset Your Password</h3>}
        body={
          <div className="modal-body">
            <div className="p-b-10">
              <p>
                Please check your email for the verification code we just sent.
              </p>
              <p>Password requirements:</p>
              <ul>
                <li>Password minimum length 8 characters</li>
                <li>Password should contains at least 1 number</li>
                <li>
                  Password should contains at least 1 special character. The
                  following characters count as special characters like &, # or
                  *
                </li>
                <li>Password should contains at least 1 uppercase letter</li>
                <li>Password should contains at least 1 lowercase letter</li>
              </ul>
            </div>
            <div className="form-container m-b-0" style={{ width: "50%" }}>
              <MDBValidation className="row g-3 needs-validation">
                <MDBValidationItem feedback="" invalid>
                  <MDBInput
                    label="Verification Code"
                    id="auth-code"
                    type="email"
                    value={authCode}
                    required
                    onChange={(event) =>
                      this.onChangeAuthCode(event.target.value)
                    }
                  />
                </MDBValidationItem>
                <MDBValidationItem feedback="" invalid>
                  <MDBInput
                    label="Password"
                    id="reset-password"
                    type="password"
                    value={password}
                    required
                    onChange={(event) =>
                      this.onChangePassword(event.target.value)
                    }
                  />
                </MDBValidationItem>
                <MDBValidationItem feedback="" invalid>
                  <MDBInput
                    label="Confirm Password"
                    id="reset-confirm-password"
                    type="password"
                    value={confirmPassword}
                    required
                    onChange={(event) =>
                      this.onChangeConfirmPassword(event.target.value)
                    }
                  />
                </MDBValidationItem>
              </MDBValidation>
            </div>
          </div>
        }
        footer={[
          <MDBBtn onClick={this.submit}>Submit</MDBBtn>,
          <MDBBtn onClick={this.dismiss} color="danger">
            Cancel
          </MDBBtn>,
        ]}
      />
    );
  }
}
export default ResetPasswordModal;
