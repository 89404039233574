// import { CognitoUser } from "amazon-cognito-identity-js";
import {
  MDBBtn,
  MDBCollapse,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBInput,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { RouterAddress } from "../constants/RouterAddress";

@inject("authStore")
@observer
class AppHeader extends React.Component<any, any> {
  // private cognitoUser: CognitoUser;

  constructor(props: any) {
    super(props);
    this.state = {
      navBarOpen: false,
    };

    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    this.props.authStore.signOut();
  }

  render() {
    return (
      <MDBNavbar expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarBrand>
            <MDBNavbarLink
              aria-current="page"
              href={RouterAddress.DASHBOARD_PROVIDER}
            >
              <MDBIcon fas icon="users" style={{ color: "#0c529c" }} />
            </MDBNavbarLink>
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() =>
              this.setState({ navBarOpen: !this.state.navBarOpen })
            }
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar open={this.state.navBarOpen}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink
                  aria-current="page"
                  href={RouterAddress.DASHBOARD_PROVIDER}
                >
                  My Homes
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem className="float-end">
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link" role="button">
                    Profile
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link>
                      <MDBNavbarLink aria-current="page" onClick={this.signOut}>
                        Log Out
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
            </MDBNavbarNav>

            {/* <form className='d-flex input-group w-auto'>
            <input type='search' className='form-control' placeholder='Type query' aria-label='Search' />
            <MDBBtn color='primary'>Search</MDBBtn>
          </form> */}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    );
  }
}

export default AppHeader;
