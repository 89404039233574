import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { RouterAddress } from "../constants/RouterAddress";
import AppHeader from "./appHeader";
import { useEffect, useState } from "react";

export function PrivateRoutes({ authStore }: any) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const authenticated = await authStore.isUserLoggedIn();
        if (authenticated) {
          setIsAuthenticated(authenticated);
        } else {
          navigate(RouterAddress.LOGIN_PAGE);
        }
      } catch (err) {
        console.error(err);
        navigate(RouterAddress.LOGIN_PAGE);
      }
    })();
  }, []);

  return isAuthenticated ? (
    <div>
      <AppHeader />
      <Outlet />
    </div>
  ) : (
    <div>Loading...</div>
  );
}
