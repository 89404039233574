export function showPluralIfNeeded(size: number, text: string): string {
  if (size <= 1){
    return text;
  } 
  return `${text}s`;
}

export const convertIsoToDate = (isoString: string | undefined): string => {
  return (new Date(isoString!)).toLocaleString('en-US').toString();
};

export function sortObjectByKey(obj: { [key: string]: any }): { [key: string]: any } {
  const sortedEntries = Object.entries(obj).sort((a, b) => a[0].localeCompare(b[0]));
  return Object.fromEntries(sortedEntries);
}
