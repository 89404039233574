import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from "amazon-cognito-identity-js";
import { action, observable } from "mobx";
import { COGNITO_APP_POOL_CONFIG } from "../constants/apiGatewayConstants";
import { RouterAddress } from "../constants/RouterAddress";

interface ISessionProperties {
  jwtToken: string;
  expirationTime: number;
  auth_Time: number;
  refreshToken: string;
  currentUserEmail: string;
}

export class AuthStore {
  private cognitoUser: CognitoUser | undefined;

  @observable sessionProp: ISessionProperties = {
    jwtToken: "",
    currentUserEmail: "",
    refreshToken: "",
    expirationTime: 0,
    auth_Time: 0,
  };
  @observable userPoolProps: CognitoUserPool;

  constructor() {
    this.userPoolProps = new CognitoUserPool(COGNITO_APP_POOL_CONFIG.DEV);
  }

  @action
  setUserProperties(cognitoUserSession: CognitoUserSession, userName: string) {
    this.sessionProp.expirationTime = cognitoUserSession
      .getIdToken()
      .getExpiration();
    this.sessionProp.auth_Time = cognitoUserSession.getIdToken().getIssuedAt();
    this.sessionProp.jwtToken = cognitoUserSession.getIdToken().getJwtToken();
    this.sessionProp.currentUserEmail = userName;
    this.sessionProp.refreshToken = cognitoUserSession
      .getRefreshToken()
      .getToken();
  }

  @action async isUserLoggedIn(): Promise<boolean> {
    this.cognitoUser = this.userPoolProps.getCurrentUser()!;
    if (this.cognitoUser == null || this.cognitoUser == undefined) {
      return Promise.resolve(false);
    }

    const sessionData = await this.getSessionFromCognito(this.cognitoUser);
    const userAttributes: CognitoUserAttribute[] =
      await this.getUserAttributesFromCognito();
    this.setUserProperties(sessionData, this.cognitoUser.getUsername());
    return Promise.resolve(true);
  }

  @action async waitForJwtToken() {
    while (this.sessionProp.jwtToken === "") {
      await this.sleep(500);
    }
    return this.sessionProp.jwtToken;
  }

  @action signOut() {
    if (this.cognitoUser != null) {
      this.cognitoUser.globalSignOut({
        onSuccess: (msg) => {
          this.cognitoUser = undefined;
          window.location.replace(RouterAddress.LOGIN_PAGE);
        },
        onFailure: (err) => {
          console.log(err);
          throw new Error("Failed to sign out");
        },
      });
    }
  }

  private sleep(time: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        // @ts-ignore
        resolve(undefined);
      }, time);
    });
  }

  getSessionFromCognito(cognitoUser: CognitoUser): Promise<CognitoUserSession> {
    return new Promise((resolve) => {
      cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          throw err;
        }
        if (!session.isValid()) {
          throw new Error("Session Is Not Valid");
        }
        resolve(session);
      });
    });
  }

  @action getNewSession() {
    this.cognitoUser = this.userPoolProps.getCurrentUser()!;
    return new Promise((resolve) => {
      this.cognitoUser!.getSession(function (err: any, session: any) {
        if (err) {
          throw err;
        }
        if (!session.isValid()) {
          throw new Error("Session Is Not Valid");
        }
        resolve(session);
      });
    });
  }

  @action getUserAttributesFromCognito(): Promise<CognitoUserAttribute[]> {
    return new Promise((resolve, reject) => {
      this.cognitoUser!.getUserAttributes((err, userAttributes) => {
        if (err) {
          reject(err);
        }
        resolve(userAttributes!);
      });
    });
  }

  @action updateUserAttributesInCognito(
    userAttributes: ICognitoUserAttributeData[],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cognitoUser!.updateAttributes(userAttributes, (err) => {
        if (err) {
          reject(err);
        }
        // @ts-ignore
        resolve(undefined);
      });
    });
  }

  //   @action async checkPhoneVerified(): Promise<boolean> {
  //     await this.getNewSession();
  //     const userAttributes: CognitoUserAttribute[] =
  //       await this.getUserAttributesFromCognito();
  //     const userAttribute: CognitoUserAttribute = userAttributes.find(
  //       (attribute) =>
  //         attribute.getName() === RegisterConstants.PHONE_VERIFIED_ATTRIBUTE
  //     );

  //     return (
  //       userAttribute !== undefined &&
  //       userAttribute.getValue() === PHONE_VERIFICATION.VERIFIED
  //     );
  //   }
}
