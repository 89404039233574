import * as React from "react";
import { isNullEmptyUndefinedString } from "../../utils/validationUtils";
import { INVALID_EMAIL_ERROR_MESSAGE } from "../../constants/message";
import { GenericModal } from "../general/genericModal";
import { SubmitButton } from "../general/submitButton";
import {
  MDBBtn,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";

export interface ISendResetEmailModal {
  isVisible: boolean;
  submitEmail: (email: string) => Promise<any>;
  dismiss: () => any;
}

class SendResetEmailModal extends React.Component<ISendResetEmailModal, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      userEmail: "",
      errorMessage: "",
      submitLoading: false,
    };
    this.submit = this.submit.bind(this);
    this.emailOnchange = this.emailOnchange.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  emailOnchange(email: string) {
    this.setState({
      userEmail: email,
    });
  }

  async submit() {
    const { userEmail } = this.state;
    const { submitEmail } = this.props;
    this.setState({ submitLoading: true });

    try {
      if (isNullEmptyUndefinedString(userEmail)) {
        this.setState({
          errorMessage: "Please provide a valid email address.",
        });
        return;
      }
      await submitEmail(userEmail);
      this.resetModal();
    } catch (e) {
      this.setState({
        submitLoading: false,
        errorMessage: "Please provide a valid email address.",
      });
    }
  }

  dismiss() {
    const { dismiss } = this.props;
    this.resetModal();
    dismiss();
  }

  resetModal() {
    this.setState({
      userEmail: "",
      errorMessage: "",
      submitLoading: false,
    });
  }

  render() {
    const { submitLoading, userEmail, errorMessage } = this.state;
    const { isVisible } = this.props;

    return (
      <GenericModal
        key="send-reset-email-modal"
        isVisible={isVisible}
        header={<h3>Generate Password Reset Link</h3>}
        body={
          <div className="modal-body">
            <p>
              Enter your email address below, and if there is an account
              associated with that email, we will send you a link to reset the
              password.
            </p>
            <MDBValidation className="row g-3 needs-validation">
              <MDBValidationItem feedback="" invalid>
                <MDBInput
                  label="Email address"
                  type="email"
                  value={userEmail}
                  required
                  onChange={(event) => this.emailOnchange(event.target.value)}
                />
              </MDBValidationItem>
            </MDBValidation>
          </div>
        }
        footer={[
          <MDBBtn onClick={this.submit}>Submit</MDBBtn>,
          <MDBBtn onClick={this.dismiss} color="danger">
            Cancel
          </MDBBtn>,
        ]}
      />
    );
  }
}

export default SendResetEmailModal;
