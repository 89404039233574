import React, { Component } from 'react';
import { 
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardSubTitle,
  MDBCardLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBCheckbox,
  MDBTooltip,
  MDBFile,
  MDBCardImage,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBRadio,
  MDBTextArea,
  MDBCardHeader,
  MDBCardFooter
} from 'mdb-react-ui-kit';
import { inject } from 'mobx-react';
import { CaregiverProfile } from '../models/caregiverProfile';
import { useParams } from "react-router-dom";
import { BusinessHomeProfile } from '../models/businessHomeProfile';
import { convertIsoToDate, showPluralIfNeeded } from '../utils/stringUtils';
import { convertFileToDocument, Document, ReferenceType } from '../models/document';
import { UserFeedback } from '../models/feedback';

function withParams(Component: any) {
  return (props) => <Component {...props} params={useParams()} />;
}

@inject('backendAPIStore')
export class DashboardCaregiver extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addNewCaregiverModalVisiblility: false,
      caregivers: [],
      caregiverDetails: CaregiverProfile,
      errorMessageAddNewCaregiver: '',
      editEnabled: false,
      businessHomeDetails: BusinessHomeProfile,
      selectedFile: null,
      previewUrl: null,

      documentModalVisiblility: false,
      documents: [],
      newDocuments: [],
      newDocumentUploadButtonDisable: [],

      feedbackModalVisiblility: false,
      feedbacks: [],
      newFeedbackDetails: UserFeedback,
      errorMessageNewFeedBack: ''
    }

    this.toggleNewCaregiverModalVisiblility = this.toggleNewCaregiverModalVisiblility.bind(this);
    this.setCaregiverDetails = this.setCaregiverDetails.bind(this);
    this.submitNewCaregiverDetails = this.submitNewCaregiverDetails.bind(this);
    this.validateNewCaregiverDetails = this.validateNewCaregiverDetails.bind(this);
    this.caregiverListHTML = this.caregiverListHTML.bind(this);
    this.noCaregiverListHTML = this.noCaregiverListHTML.bind(this);
    this.editCaregiverDetails = this.editCaregiverDetails.bind(this);
    this.deleteCaregiverDetails = this.deleteCaregiverDetails.bind(this);
    this.manageDocuments = this.manageDocuments.bind(this);
    this.manageFeedbacks = this.manageFeedbacks.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleNewDocuments = this.handleNewDocuments.bind(this)

    this.toggleDocumentModalVisibility = this.toggleDocumentModalVisibility.bind(this);
    this.toggleFeedbackModalVisibility = this.toggleFeedbackModalVisibility.bind(this);
    this.handleUploadProfilePic = this.handleUploadProfilePic.bind(this);
    this.onSubmitNewDocuments = this.onSubmitNewDocuments.bind(this);
    this.documentListHTML = this.documentListHTML.bind(this);

    this.downloadDocument = this.downloadDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.deleteFeedback = this.deleteFeedback.bind(this);

    this.submitNewFeedback = this.submitNewFeedback.bind(this);
  }

  async componentDidMount(){
    this.setState({caregivers: []});
    await this.props.backendAPIStore.init()
    const caregiversResponse = await this.props.backendAPIStore.getCaregivers(this.props.params.businessHomeRangeId);
    const businessHomeResponse = await this.props.backendAPIStore.getBusinessHomeDetails(this.props.params.businessHomeRangeId);
    this.setState({
      caregivers: caregiversResponse,
      businessHomeRangeId: this.props.params.businessHomeRangeId,
      businessHomeDetails: businessHomeResponse
    }, () => {});
  }

  toggleNewCaregiverModalVisiblility(visible: boolean) {
    this.setState({
      addNewCaregiverModalVisiblility: visible,
      caregiverDetails: new CaregiverProfile(),
      errorMessageAddNewCaregiver: '',
      editEnabled: false,
      selectedFile: null,
      previewUrl: null,
    }, () => {
      this.setState({caregiverDetails: {...this.state.caregiverDetails, id: this.state.businessHomeRangeId}}, () => {
        
      })
    });
  }

  toggleFeedbackModalVisibility(visible: boolean) {
    this.setState({
      feedbackModalVisiblility: visible,
      newFeedbackDetails: new UserFeedback(),
    }, async () => {
      
    });
  }

  setFeedbackDetails(event: any) {
    this.setState({ newFeedbackDetails: {...this.state.newFeedbackDetails, [event.target.name]: event.target.value }});
  }

  async submitNewFeedback() {
    var errorMessage = '';
    if (!this.state.newFeedbackDetails.feedbackType) {
      errorMessage = 'Please select feedback type';
    } else if (!this.state.newFeedbackDetails.feedbackComment) {
      errorMessage = 'Please add the comment';
    }

    if(errorMessage !== '') {
      this.setState({errorMessageNewFeedBack: errorMessage});
      return;
    }

    this.setState({errorMessageNewFeedBack: ''});
    this.setState({newFeedbackDetails: {...this.state.newFeedbackDetails, id: this.state.caregiverDetails.rangeId}}, async () => {
      await this.props.backendAPIStore.addFeedback(this.state.newFeedbackDetails);
      this.setState({
        feedbacks: await this.props.backendAPIStore.listAllFeedbackForCaregiver(this.state.caregiverDetails.rangeId)
      });
      // this.setState({newFeedbackDetails: new UserFeedback()});
      this.toggleFeedbackModalVisibility(false);
    });
    
  }

 manageFeedbacks(caregiver: CaregiverProfile) {
  this.setState({
    feedbackModalVisiblility: true, 
    caregiverDetails: caregiver,
  }, async () => {
    this.setState({
      feedbacks: await this.props.backendAPIStore.listAllFeedbackForCaregiver(this.state.caregiverDetails.rangeId)
    }, () => {
      console.log(this.state.feedbacks);
    })
  })
};

async deleteFeedback(feedback: UserFeedback) {
  await this.props.backendAPIStore.deleteFeedback(feedback);
  this.setState({
    feedbacks: await this.props.backendAPIStore.listAllFeedbackForCaregiver(this.state.caregiverDetails.rangeId)
  });
}

  handleFileChange = (event) => {
    if (event.target.files.length !== 1) {
      return;
    }

    const file = event.target.files[0];
    if(file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.setState({errorMessageAddNewCaregiver: 'Please only upload image file. Supported file types are JPG, JPEG & PNG'});
      return;
    }

    this.setState({
      selectedFile: event.target.files[0],
      previewUrl: URL.createObjectURL(event.target.files[0]),
    });
  };

  handleNewDocuments = (event) => {
    if (event.target.files.length == 0) {
      return;
    }

    this.setState({
      newDocumentUploadButtonDisable: false,
      newDocuments: event.target.files,
    });
  }

  onSubmitNewDocuments = async (event) => {
    if (this.state.newDocuments.length == 0) {
      return;
    }

    await this.processAllDocument();
    this.setState({
      newDocuments: [],
      toggleDocumentModalVisibility: false,
      documents: await this.props.backendAPIStore.listAllDocument(this.state.caregiverDetails.rangeId),
    });
  }

  async processAllDocument() {
    for (let index = 0; index < this.state.newDocuments.length; index++) {
      await this.processDocument(this.state.newDocuments[index]);
    }
  }

  async processDocument(file: File) {
    const document = convertFileToDocument(file, this.state.caregiverDetails.rangeId, ReferenceType.CAREGIVER);
    const dbResponse = await this.props.backendAPIStore.addDocument(document);
    const uploadUrl = await this.props.backendAPIStore.getPresignedUrlForUpload(this.props.params.businessHomeRangeId, document.s3Key);
    await this.props.backendAPIStore.uploadFileToS3(uploadUrl, file);
  }

  handleUploadProfilePic = async (event) => {
    if (this.state.selectedFile) {
      const url = await this.props.backendAPIStore.getPresignedUrlForUpload(this.props.params.businessHomeRangeId, 'caregiver/pic/' + this.state.caregiverDetails.rangeId);
      await this.props.backendAPIStore.uploadFileToS3(url, this.state.selectedFile);
    }
  };

  setCaregiverDetails(event: any) {
    this.setState({ caregiverDetails: {...this.state.caregiverDetails, [event.target.name]: event.target.value }})
  }

  async submitNewCaregiverDetails(e: any) {
    const isValidInput = this.validateNewCaregiverDetails()
    if (!isValidInput) {
      return;
    }

    if (!this.state.editEnabled) {
      await this.props.backendAPIStore.addCaregiver(this.state.caregiverDetails);
    } else {
      await this.props.backendAPIStore.updateCaregiver(this.state.caregiverDetails);
    }
    
    const response = await this.props.backendAPIStore.getCaregivers(this.props.params.businessHomeRangeId);
    this.setState({caregivers: response}, () => {
    });
    this.toggleNewCaregiverModalVisiblility(false);
  }

  validateNewCaregiverDetails() {
    if (!this.state.caregiverDetails 
      || !this.state.caregiverDetails.firstName
      || !this.state.caregiverDetails.lastName
      || !this.state.caregiverDetails.email
      || !this.state.caregiverDetails.phoneNumber
      || !this.state.caregiverDetails.addressLine1
      || !this.state.caregiverDetails.city
      || !this.state.caregiverDetails.zipcode
    ) {
        this.setState({errorMessageAddNewCaregiver: 'Please provide all the required inputs.'});
        return false;
    }

    this.setState({errorMessageAddNewCaregiver: ''})
    return true;
  }

  editCaregiverDetails(caregiver: CaregiverProfile) {
    this.setState({
      addNewCaregiverModalVisiblility: true, 
      editEnabled: true,
      caregiverDetails: caregiver,
      previewUrl: null,
    }, async () => {
      const url = await this.props.backendAPIStore.getPresignedUrlForDownload(this.props.params.businessHomeRangeId, 'caregiver/pic/' + this.state.caregiverDetails.rangeId);
      this.setState({previewUrl: url})
    });
  }

  deleteCaregiverDetails(caregiver: CaregiverProfile) {

  }

  manageDocuments(caregiver: CaregiverProfile) {
    this.setState({
      caregiverDetails: caregiver,
    }, async () => {
      this.setState({
        documents: await this.props.backendAPIStore.listAllDocument(this.state.caregiverDetails.rangeId)
      }, () => {
        this.setState({documentModalVisiblility: true});
      })
    });
  }

  async downloadDocument(document: Document) {
    const s3Key = `${ReferenceType.CAREGIVER}/${document.id}/${document.rangeId}`
    const url = await this.props.backendAPIStore.getPresignedUrlForDownload(this.props.params.businessHomeRangeId, s3Key);
    window.open(url);
  }

  async deleteDocument(document: Document) {
    await this.props.backendAPIStore.deleteDocument(document);
    this.setState({
      documents: await this.props.backendAPIStore.listAllDocument(this.state.caregiverDetails.rangeId)
    });
  }

  toggleDocumentModalVisibility(visible: boolean) {
    this.setState({
      documentModalVisiblility: visible,
      newDocumentUploadButtonDisable: true,
    }, async () => {
      
    });
  }



  noCaregiverListHTML() {
    return (
      // <MDBContainer>
      //   <MDBRow>
      //     <MDBCol>
      //       <div>
      //         Looks like you haven't added any caregiver yet. 
      //       </div>
      //       <MDBBtn onClick={() => this.toggleNewCaregiverModalVisiblility(true)}>
      //         Add new caregiver
      //       </MDBBtn>
      //     </MDBCol>
      //   </MDBRow>
      // </MDBContainer>
      <div>
        <div>
          Looks like you haven't added any caregiver yet. 
        </div>
        <MDBBtn onClick={() => this.toggleNewCaregiverModalVisiblility(true)}>
          Add new caregiver
        </MDBBtn>
      </div>
    )
  }

  caregiverListHTML() {
    return (
      <div>
        <div style={{margin: "1em"}}>
          <div>
            You have {this.state.caregivers.length} registered {showPluralIfNeeded(this.state.caregivers.length, 'caregiver')} for Home: <strong>{this.state.businessHomeDetails.businessName}</strong>
            {
              !this.state.businessHomeDetails.active ? <div style={{color: "red"}}>&nbsp;This home is not active</div>: null
            }
          </div>
          <MDBBtn onClick={() => this.toggleNewCaregiverModalVisiblility(true)} style={{margin: "1em", clear: "both"}}>
            Add new caregiver
          </MDBBtn>
        </div>
        {
          this.state.caregivers.map((caregiver: CaregiverProfile) => (
            <MDBCard key={caregiver.firstName} className='screen' style={{margin: "1em", cursor: "pointer", textAlign: "left"}}>
              {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/184.webp' position='top' alt='...' style={{width: "15em", height: "15em"}}/> */}
              <MDBCardBody>
                <MDBCardTitle>
                  {caregiver.firstName}&nbsp;{caregiver.lastName}
                 <MDBDropdown group className="float-end">
                    <MDBDropdownToggle color='info'>Action</MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link onClick={() => this.editCaregiverDetails(caregiver)}>Edit Details</MDBDropdownItem>
                      <MDBDropdownItem link onClick={() => this.manageDocuments(caregiver)}>Manage Documents</MDBDropdownItem>
                      <MDBDropdownItem link onClick={() => this.manageFeedbacks(caregiver)}>Manage Feedback</MDBDropdownItem>
                      {/* <MDBDropdownItem link onClick={() => this.deleteCaregiverDetails(caregiver)}>Delete Caregiver</MDBDropdownItem> */}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBCardTitle>
                <MDBCardSubTitle>
                  {caregiver.addressLine1}, {caregiver.city}, {caregiver.state} - {caregiver.zipcode}
                </MDBCardSubTitle>
                <MDBCardText>
                  {
                    !caregiver.active ? (
                      <div>
                        <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title="Caregiver is not active. To make it active, edit caregiver details.">
                          <span style={{color: 'red'}}>Caregiver is not Active</span>
                        </MDBTooltip>
                      </div>)
                      : null
                  }
                </MDBCardText>
              </MDBCardBody>
          </MDBCard>
        ))}
      </div>
    )
  }

  documentListHTML() {
    return (
      <div>
        <h4>List of Documents:</h4>
        {
          this.state.documents.map((document: Document, index: number) => (
          <MDBCard style={{margin: "1em"}}>
              <MDBCardBody>
                <MDBCardText>
                  {document.documentTitle}
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter className='text-muted'>
                {convertIsoToDate(document.createdAt)}
                <div className='float-end'>
                  <span onClick={() => this.downloadDocument(document)} title='Download'>
                    <MDBIcon fas icon="cloud-download-alt" style={{color: "gray", cursor: "pointer"}}/>
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span onClick={() => this.deleteDocument(document)} title='Delete'>
                    <MDBIcon fas icon="trash-alt" style={{color: "red", cursor: "pointer"}}/>
                  </span>
                </div>
              </MDBCardFooter>
            </MDBCard>
          ))
        }
      </div>
    )
  }

  feedbackListHTML() {
    return (
      <div>
        <h4>List of feedbacks:</h4>
        {
          this.state.feedbacks.map((feedback: UserFeedback, index: number) => (
          <MDBCard style={{margin: "1em"}}>
            <MDBCardHeader>{feedback.feedbackType} feedback</MDBCardHeader>
              <MDBCardBody>
                <MDBCardText>
                  {feedback.feedbackComment}
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter className='text-muted'>
                {convertIsoToDate(feedback.createdAt)}
                <span onClick={() => this.deleteFeedback(feedback)} title='Delete' className='float-end'>
                  <MDBIcon fas icon="trash-alt" style={{color: "red", cursor: "pointer"}}/>
                </span>
              </MDBCardFooter>
            </MDBCard>
          ))
        }
      </div>
    )
  }
  
  render() {
    return (
      <div style={{textAlign: 'center'}} className="pageContainer">
        {
          this.state.caregivers.length === 0 ? (
            this.noCaregiverListHTML()
          ): (
            this.caregiverListHTML()
          )
        }
          
        <MDBModal open={this.state.addNewCaregiverModalVisiblility} onClose={()=>this.toggleNewCaregiverModalVisiblility(false)} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Caregiver Details</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={()=>this.toggleNewCaregiverModalVisiblility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                { this.state.editEnabled ? (
                  <div>
                    {
                      (this.state.selectedFile || this.state.previewUrl) ? 
                      <img src={this.state.previewUrl} style={{width: "15em", height: "15em"}} />: null
                    }
                    <MDBFile id='customFile' onChange={(e)=>this.handleFileChange(e)} />
                    <MDBBtn onClick={(e)=>this.handleUploadProfilePic(e)} disabled={!this.state.selectedFile}>Upload</MDBBtn>
                    <br/>
                  </div>): null
                }
                <MDBValidation className="row g-3 needs-validation">
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="First Name"
                      name="firstName"
                      id="firstName"
                      type="text"
                      value={this.state.caregiverDetails.firstName}
                      required
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Last Name"
                      name="lastName"
                      id="lastName"
                      type="text"
                      value={this.state.caregiverDetails.lastName}
                      required
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Email"
                      name="email"
                      id="email"
                      type="email"
                      value={this.state.caregiverDetails.email}
                      required
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Phone Number"
                      name="phoneNumber"
                      id="phoneNumber"
                      type="number"
                      value={this.state.caregiverDetails.phoneNumber}
                      required
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Address Line 1"
                      name="addressLine1"
                      id="addressLine1"
                      type="text"
                      value={this.state.caregiverDetails.addressLine1}
                      required
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Address Line 2"
                      name="addressLine2"
                      id="addressLine2"
                      type="text"
                      value={this.state.caregiverDetails.addressLine2}
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="City"
                      name="city"
                      id="city"
                      type="text"
                      value={this.state.caregiverDetails.city}
                      required
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="State"
                      name="state"
                      id="state"
                      type="text"
                      value={this.state.caregiverDetails.state}
                      required
                      disabled
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Country"
                      name="country"
                      id="country"
                      type="text"
                      value={this.state.caregiverDetails.country}
                      required
                      disabled
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="zipcode"
                      name="zipcode"
                      id="zipcode"
                      type="text"
                      value={this.state.caregiverDetails.zipcode}
                      required
                      onChange={(event) => this.setCaregiverDetails(event)}
                    />
                  </MDBValidationItem>
                  {
                    this.state.editEnabled ? (
                      <MDBValidationItem feedback="" invalid>
                        <MDBCheckbox 
                        name='active' 
                        checked={this.state.caregiverDetails.active} 
                        id='active' 
                        label='Active' 
                        onChange={(event) => {
                          this.setState({ caregiverDetails: {...this.state.caregiverDetails, [event.target.name]: event.target.checked }})
                        }}/>
                      </MDBValidationItem>
                    ): null
                  }
                  
                  <MDBModalFooter>
                    <MDBBtn color='secondary' onClick={()=>this.toggleNewCaregiverModalVisiblility(false)}>
                      Cancel
                    </MDBBtn>
                    <MDBBtn type='submit' onClick={(e) => this.submitNewCaregiverDetails(e)}>Submit</MDBBtn>
                    <div style={{color: 'red'}}>{this.state.errorMessageAddNewCaregiver}</div>
                  </MDBModalFooter>
                </MDBValidation>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal open={this.state.documentModalVisiblility} onClose={()=>this.toggleDocumentModalVisibility(false)} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Documents Uploaded: {this.state.documents.length}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={()=>this.toggleDocumentModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBCard style={{margin: "1em"}}>
                  <MDBCardBody>
                    <MDBCardTitle>
                      Add new documents
                    </MDBCardTitle>
                    
                    <MDBCardText>
                      <MDBFile id='customFileNewDocuments' onChange={(e)=>this.handleNewDocuments(e)} multiple/>
                        <br />
                      <MDBBtn onClick={(e)=>this.onSubmitNewDocuments(e)} disabled={this.state.newDocumentUploadButtonDisable}>Upload</MDBBtn>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
                {
                  this.state.documents.length > 0 ? (this.documentListHTML()): null
                }
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal open={this.state.feedbackModalVisiblility} onClose={()=>this.toggleFeedbackModalVisibility(false)} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Feedback ({this.state.feedbacks.length})</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={()=>this.toggleFeedbackModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
              <MDBCard style={{margin: "1em"}}>
                  <MDBCardBody>
                    <MDBCardTitle>
                      Add New Feedback
                    </MDBCardTitle>
                    
                    <MDBCardText>
                    <MDBValidation className="row g-3 needs-validation">
                      <MDBValidationItem feedback="" invalid>
                      <div>
                        <span color='info'>Feedback Type&nbsp;&nbsp;</span>
                        <MDBRadio name='feedbackType' value='Positive' label='Positive' inline onClick={(event) => this.setFeedbackDetails(event)}/>
                        <MDBRadio name='feedbackType' value='Negative' label='Negative' inline onClick={(event) => this.setFeedbackDetails(event)}/>
                      </div>
                      </MDBValidationItem>
                      <MDBValidationItem feedback="" invalid>
                        <MDBTextArea
                          label="Comment"
                          name="feedbackComment"
                          id="feedbackComment"
                          rows={4}
                          value={this.state.newFeedbackDetails.feedbackComment}
                          required
                          onChange={(event) => this.setFeedbackDetails(event)}
                        />
                      </MDBValidationItem>
                      
                      <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={()=>this.toggleFeedbackModalVisibility(false)}>
                          Cancel
                        </MDBBtn>
                        <MDBBtn type='submit' onClick={() => this.submitNewFeedback()}>Submit</MDBBtn>
                      </MDBModalFooter>
                      <div style={{color: 'red'}}>{this.state.errorMessageNewFeedBack}</div>
                    </MDBValidation>
                    {
                      this.state.feedbacks.length > 0 ? this.feedbackListHTML(): null
                    }
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    )
  };
}

export default withParams(DashboardCaregiver);
