import { AuthStore } from "./authStore";
import { action } from "mobx";
import axios from "axios";
import { BusinessHomeProfile } from "../models/businessHomeProfile";
import { CaregiverProfile } from "../models/caregiverProfile";
import { Document } from "../models/document";
import { Feedback } from "aws-sdk/clients/guardduty";
import { UserFeedback } from "../models/feedback";

enum HTTPMethod {
  GET,
  POST,
  PUT,
  DELETE
}

export class BackendAPIStore {
  readonly authStore: AuthStore;
  private jwtToken: string = "";
  private API_ENDPOINT: string = "https://hnrtwpi6k5.execute-api.us-west-2.amazonaws.com/prod"

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action async init() {
    this.jwtToken = await this.authStore.waitForJwtToken();
  }

  async invokeApi(httpMethod: HTTPMethod, url: string, requestBody?: object) {
    try {
      var response: any;
      const headers = {
        headers: {
          authorization: this.jwtToken,
        },
      }

      if (httpMethod === HTTPMethod.GET) {
        response = await axios.get(url, headers);
      } else if (httpMethod === HTTPMethod.PUT) {
        response = await axios.put(url, requestBody, headers);
      } else if (httpMethod === HTTPMethod.POST) {
        response = await axios.post(url, requestBody, headers);
      } else if (httpMethod === HTTPMethod.DELETE) {
        response = await axios.delete(url, headers);
      }

      return response.data;
    } catch (err) {
        console.error(err);
    }
  }

  public async uploadFileToS3(preSignedUrl: string, file: File) {
    try {
      const response = await axios.put(preSignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  public async getPresignedUrlForDownload(businessHomeId: string, objectKey: string) {
    const requestBody = {
      businessHomeId: businessHomeId,
      objectKey: objectKey
    }
    return await this.invokeApi(HTTPMethod.PUT,  `${this.API_ENDPOINT}/presignedUrlForDownload`, requestBody);
  }

  public async getBusinessHomeDetails(id: string) {
    return await this.invokeApi(HTTPMethod.GET, `${this.API_ENDPOINT}/businessHome?rangeId=${id}`);
  }

  public async getBusinessHomeListForProvider() {
    return await this.invokeApi(HTTPMethod.GET,  `${this.API_ENDPOINT}/businessHome?list=activeUser`);
  }

  public async addBusinessHome(businessHome: BusinessHomeProfile) {
    return await this.invokeApi(HTTPMethod.POST,  `${this.API_ENDPOINT}/businessHome`, businessHome);
  }

  public async updateBusinessHome(businessHome: BusinessHomeProfile) {
    return await this.invokeApi(HTTPMethod.PUT,  `${this.API_ENDPOINT}/businessHome`, businessHome);
  }

  public async getCaregivers(id: string) {
    return await this.invokeApi(HTTPMethod.GET, `${this.API_ENDPOINT}/caregiver?id=${id}`);
  }

  public async addCaregiver(caregiver: CaregiverProfile) {
    return await this.invokeApi(HTTPMethod.POST,  `${this.API_ENDPOINT}/caregiver`, caregiver);
  }

  public async updateCaregiver(caregiver: CaregiverProfile) {
    return await this.invokeApi(HTTPMethod.PUT,  `${this.API_ENDPOINT}/caregiver`, caregiver);
  }

  public async getPresignedUrlForUpload(businessHomeId: string, objectKey: string) {
    const requestBody = {
      businessHomeId: businessHomeId,
      objectKey: objectKey
    }
    return await this.invokeApi(HTTPMethod.PUT,  `${this.API_ENDPOINT}/presignedUrlForUpload`, requestBody);
  }

  public async addDocument(document: Document) {
    return await this.invokeApi(HTTPMethod.POST,  `${this.API_ENDPOINT}/document`, document);
  }

  public async updateDocument(document: Document) {
    return await this.invokeApi(HTTPMethod.PUT,  `${this.API_ENDPOINT}/document`, document);
  }

  public async deleteDocument(document: Document) {
    return await this.invokeApi(HTTPMethod.DELETE,  `${this.API_ENDPOINT}/document?id=${document.id}&rangeId=${document.rangeId}`);
  }

  public async listAllDocument(id: string) {
    return await this.invokeApi(HTTPMethod.GET,  `${this.API_ENDPOINT}/document?id=${id}`);
  }

  public async getBlobFromURL(url: string) {
    return await axios.get(url, {responseType: 'blob'});
  }

  public async addFeedback(feedback: UserFeedback) {
    return await this.invokeApi(HTTPMethod.POST,  `${this.API_ENDPOINT}/caregiverFeedback`, feedback);
  }

  public async updateFeedback(feedback: UserFeedback) {
    return await this.invokeApi(HTTPMethod.PUT,  `${this.API_ENDPOINT}/caregiverFeedback`, feedback);
  }

  public async deleteFeedback(feedback: UserFeedback) {
    return await this.invokeApi(HTTPMethod.DELETE,  `${this.API_ENDPOINT}/caregiverFeedback?id=${feedback.id}&rangeId=${feedback.rangeId}`);
  }

  public async listAllFeedbackForCaregiver(id: string) {
    return await this.invokeApi(HTTPMethod.GET,  `${this.API_ENDPOINT}/caregiverFeedback?id=${id}`);
  }

  public async getResidents(id: string) {
    return await this.invokeApi(HTTPMethod.GET, `${this.API_ENDPOINT}/resident?id=${id}`);
  }

  public async addResident(caregiver: CaregiverProfile) {
    return await this.invokeApi(HTTPMethod.POST,  `${this.API_ENDPOINT}/resident`, caregiver);
  }

  public async updateResident(caregiver: CaregiverProfile) {
    return await this.invokeApi(HTTPMethod.PUT,  `${this.API_ENDPOINT}/resident`, caregiver);
  }

}
