import React from "react";
import { Provider } from "mobx-react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { UnsignInCognitoHelper } from "./utils/unsignInCognitoHelper";
import { AuthStore } from "./store/authStore";
import { BackendAPIStore } from "./store/backendAPIStore";

const cognitoHelper = new UnsignInCognitoHelper();
const authStore: AuthStore = new AuthStore();
const backendAPIStore: BackendAPIStore = new BackendAPIStore(authStore);

const stores = {
  authStore,
  cognitoHelper,
  backendAPIStore,
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider {...stores}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
