import * as React from "react";
import styled from "styled-components";

type IModal = {
  isVisible: boolean;
  maxWidth?: number;
  body?: React.ReactNode | React.ReactNode[];
  header?: React.ReactNode;
  footer?: React.ReactNode | React.ReactNode[];
};

const ModalContainer = styled.div<IModal>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.isVisible ? 9999 : -9999)};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  -webkit-transition:
    opacity 300ms,
    visibility 300ms;
  transition:
    opacity 300ms,
    visibility 300ms;

  .modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background: rgba(15, 23, 30, 0.8);
  }

  .modal-container {
    position: relative;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 20px 30px;
    width: calc(100% - 2rem);
    max-width: ${(props) => (props.maxWidth! > 450 ? props.maxWidth : 900)}px;
    z-index: 9999;
    max-height: calc(100vh - 30px);
  }
  .modal-container.modal-narrow {
    max-width: 550px;
  }
  @media (max-width: 999.98px) {
    .modal-container {
      padding: 15px;
    }
  }
  @media (max-width: 999.98px) {
    .modal-header {
      padding-bottom: 10px;
    }
  }
  .modal-close {
    position: absolute;
    top: -30px;
    right: -35px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    z-index: 9999;
  }
  .modal-header {
    margin: 0 auto 20px auto;
    border-bottom: 2px solid #e7e7e7;
    padding-bottom: 15px;
  }
  .modal-header h1,
  .modal-header h2,
  .modal-header h3,
  .modal-header h4,
  .modal-header h5,
  .modal-header h6,
  .modal-header p {
    margin-bottom: 0;
  }
  .modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-top: 2px solid #e7e7e7;
    margin-top: 20px;
    padding-top: 20px;
  }
`;

export class GenericModal extends React.Component<IModal, any> {
  render() {
    const { header, body, footer, isVisible, maxWidth } = this.props;
    return (
      <ModalContainer isVisible={isVisible} maxWidth={maxWidth}>
        <div className="modal-mask" />
        <div className="modal-container">
          <div className="modal-header">{header}</div>
          <div className="modal-body">{body}</div>
          <div className="modal-footer">{footer}</div>
        </div>
      </ModalContainer>
    );
  }
}
