import * as React from "react";
import { GenericModal } from "../general/genericModal";
import { MDBBtn } from "mdb-react-ui-kit";

export interface IResetPasswordSuccessModal {
  isVisible: boolean;
  dismiss: () => any;
}

export class ResetPasswordSuccessModal extends React.Component<
  IResetPasswordSuccessModal,
  any
> {
  constructor(props: any) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
  }

  dismiss() {
    const { dismiss } = this.props;
    dismiss();
  }

  render() {
    const { isVisible } = this.props;
    return (
      <GenericModal
        key="reset-password-success-modal"
        isVisible={isVisible}
        header={<h3>Password Changed Successfully</h3>}
        body={
          <div className="modal-body">
            <div className="p-b-10">
              <p>
                Password changed successfully. Please log in with the new
                password.
              </p>
            </div>
          </div>
        }
        footer={[
          <MDBBtn type="button" onClick={this.dismiss}>
            Confirm
          </MDBBtn>,
        ]}
      />
    );
  }
}
export default ResetPasswordSuccessModal;
