export class BusinessHomeProfile {
    id: string | undefined;
    rangeId: string | undefined;
    
    businessName: string | undefined;

    providerFirstName: string | undefined;
    providerLastName: string | undefined;
    providerEmail: string | undefined;
    providerPhoneNumber: string | undefined;

    businessPhoneNumber: string | undefined;
    businessFaxNumber: string | undefined;
    businessAddressLine1: string | undefined;
    businessAddressLine2: string | undefined;
    city: string | undefined;
    state: string = 'WA';
    country: string = 'US';
    zipcode: string | undefined;

    active: boolean = true;
    createdAt: string | undefined;
    createdBy: string | undefined;
    modifiedAt: string | undefined;
    modifiedBy: string | undefined;
}