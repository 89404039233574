export class UserFeedback {
    id: string | undefined;
    rangeId: string | undefined;

    feedbackType: string | undefined;
    feedbackComment: string | undefined;

    active: boolean = true;
    createdAt: string | undefined;
    createdBy: string | undefined;
    modifiedAt: string | undefined;
    modifiedBy: string | undefined;
}