import React from 'react';
import { 
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardSubTitle,
  MDBCardLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBCheckbox,
  MDBTooltip,
  MDBCardHeader,
  MDBCardFooter,
  MDBFile,
  MDBIcon
} from 'mdb-react-ui-kit';
import { BusinessHomeProfile } from '../models/businessHomeProfile';
import { inject } from 'mobx-react';
import { RouterAddress } from '../constants/RouterAddress';
import { convertIsoToDate } from '../utils/stringUtils';
import { convertFileToDocument, Document, ReferenceType } from '../models/document';

@inject('backendAPIStore')
export class DashboardProvider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addNewHomeModalVisiblility: false,
      businessHomes: [],
      businessHomeDetails: BusinessHomeProfile,
      errorMessageAddNewHome: '',
      editEnabled: false,

      documentModalVisiblility: false,
      documents: [],
      newDocuments: [],
      newDocumentUploadButtonDisable: [],
    }

    this.toggleNewHomeModalVisiblility = this.toggleNewHomeModalVisiblility.bind(this);
    this.setBusinessHomeDetails = this.setBusinessHomeDetails.bind(this);
    this.submitNewHomeDetails = this.submitNewHomeDetails.bind(this);
    this.validateNewHomeDetails = this.validateNewHomeDetails.bind(this);
    this.homeListHTML = this.homeListHTML.bind(this);
    this.noHomeListHTML = this.noHomeListHTML.bind(this);
    this.editHomedetails = this.editHomedetails.bind(this);

    this.manageDocuments = this.manageDocuments.bind(this);
    this.onSubmitNewDocuments = this.onSubmitNewDocuments.bind(this);
    this.documentListHTML = this.documentListHTML.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
  }

  async componentDidMount(){
    this.setState({businessHomeDetails: new BusinessHomeProfile()});
    await this.props.backendAPIStore.init()
    const response = await this.props.backendAPIStore.getBusinessHomeListForProvider();
    this.setState({businessHomes: response});
  }

  toggleNewHomeModalVisiblility(visible: boolean) {
    this.setState({
      addNewHomeModalVisiblility: visible,
      businessHomeDetails: new BusinessHomeProfile(),
      errorMessageAddNewHome: '',
      editEnabled: false,
    });
  }

  setBusinessHomeDetails(event: any) {
    this.setState({ businessHomeDetails: {...this.state.businessHomeDetails, [event.target.name]: event.target.value }})
  }

  async submitNewHomeDetails(e: any) {
    const isValidInput = this.validateNewHomeDetails()
    if (!isValidInput) {
      return;
    }

    if (!this.state.editEnabled) {
      await this.props.backendAPIStore.addBusinessHome(this.state.businessHomeDetails);
    } else {
      await this.props.backendAPIStore.updateBusinessHome(this.state.businessHomeDetails);
    }
    
    const response = await this.props.backendAPIStore.getBusinessHomeListForProvider();
    this.setState({businessHomes: response});
    this.toggleNewHomeModalVisiblility(false);
  }

  validateNewHomeDetails() {
    if (!this.state.businessHomeDetails 
      || !this.state.businessHomeDetails.businessName
      || !this.state.businessHomeDetails.providerFirstName
      || !this.state.businessHomeDetails.providerLastName
      || !this.state.businessHomeDetails.providerEmail
      || !this.state.businessHomeDetails.providerPhoneNumber
      || !this.state.businessHomeDetails.businessPhoneNumber
      || !this.state.businessHomeDetails.businessFaxNumber
      || !this.state.businessHomeDetails.businessAddressLine1
      || !this.state.businessHomeDetails.city
      || !this.state.businessHomeDetails.zipcode
    ) {
        this.setState({errorMessageAddNewHome: 'Please provide all the required inputs.'});
        return false;
    }

    if (this.state.editEnabled && !this.state.businessHomeDetails.id){
      this.setState({errorMessageAddNewHome: 'Invalid business id'});
      return false;
    }

    this.setState({errorMessageAddNewHome: ''})
    return true;
  }

  handleNewDocuments = (event) => {
    if (event.target.files.length == 0) {
      return;
    }

    this.setState({
      newDocumentUploadButtonDisable: false,
      newDocuments: event.target.files,
    });
  }

  onSubmitNewDocuments = async (event) => {
    if (this.state.newDocuments.length == 0) {
      return;
    }

    await this.processAllDocument();
    this.setState({
      newDocuments: [],
      toggleDocumentModalVisibility: false,
      documents: await this.props.backendAPIStore.listAllDocument(this.state.businessHomeDetails.rangeId),
    });
  }

  async processAllDocument() {
    for (let index = 0; index < this.state.newDocuments.length; index++) {
      await this.processDocument(this.state.newDocuments[index]);
    }
  }

  async processDocument(file: File) {
    const document = convertFileToDocument(file, this.state.businessHomeDetails.rangeId, ReferenceType.BUSINESS_HOME);
    const dbResponse = await this.props.backendAPIStore.addDocument(document);
    const uploadUrl = await this.props.backendAPIStore.getPresignedUrlForUpload(this.state.businessHomeDetails.rangeId, document.s3Key);
    await this.props.backendAPIStore.uploadFileToS3(uploadUrl, file);
  }

  editHomedetails(home: BusinessHomeProfile) {
    this.setState({
      addNewHomeModalVisiblility: true, 
      editEnabled: true,
      businessHomeDetails: home
    });
  }

  manageDocuments(businessHome: BusinessHomeProfile) {
    this.setState({
      businessHomeDetails: businessHome,
    }, async () => {
      this.setState({
        documents: await this.props.backendAPIStore.listAllDocument(this.state.businessHomeDetails.rangeId)
      }, () => {
        this.setState({documentModalVisiblility: true});
      })
    });
  }

  async downloadDocument(document: Document) {
    const s3Key = `${ReferenceType.BUSINESS_HOME}/${document.id}/${document.rangeId}`
    const url = await this.props.backendAPIStore.getPresignedUrlForDownload(this.state.businessHomeDetails.rangeId, s3Key);
    window.open(url);
  }

  async deleteDocument(document: Document) {
    await this.props.backendAPIStore.deleteDocument(document);
    this.setState({
      documents: await this.props.backendAPIStore.listAllDocument(this.state.businessHomeDetails.rangeId)
    });
  }

  toggleDocumentModalVisibility(visible: boolean) {
    this.setState({
      documentModalVisiblility: visible,
      newDocumentUploadButtonDisable: true,
    }, async () => {
      
    });
  }

  noHomeListHTML() {
    return (
      <div>
        <div>
          Looks like you haven't added any business home yet. 
        </div>
        <MDBBtn onClick={() => this.toggleNewHomeModalVisiblility(true)}>
          Add Business Home
        </MDBBtn>
      </div>
    )
  }

  homeListHTML() {
    return (
      <div>
        <div style={{margin: "1em"}}>
          <div>
            You have {this.state.businessHomes.length} registered homes.
          </div>
          <MDBBtn onClick={() => this.toggleNewHomeModalVisiblility(true)} style={{margin: "1em", clear: "both"}}>
            Add Business Home
          </MDBBtn>
        </div>
        {
          this.state.businessHomes.map((home: BusinessHomeProfile) => (
            <MDBCard key={home.businessName} className='screen' style={{margin: "1em", cursor: "pointer", textAlign: "left"}}>
              <MDBCardHeader>Provider: {home.providerFirstName}&nbsp;{home.providerLastName}</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>
                  {home.businessName}
                  <MDBDropdown group className="float-end">
                    <MDBDropdownToggle color='info'>Action</MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link onClick={() => this.editHomedetails(home)}>Edit Home Details</MDBDropdownItem>
                      <MDBDropdownItem link onClick={() => this.manageDocuments(home)}>Manage Documents</MDBDropdownItem>
                      <MDBDropdownItem link href={RouterAddress.getHomeResidentsDashboardPagePath(home.rangeId!)}>Manage Residents</MDBDropdownItem>
                      <MDBDropdownItem link href={RouterAddress.getHomeCaregiverDashboardPagePath(home.rangeId!)}>Manage Caregivers</MDBDropdownItem>
                      <MDBDropdownItem link>Manage Website</MDBDropdownItem>
                      <MDBDropdownItem link>Manage Notes</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBCardTitle>
                <MDBCardSubTitle>
                  {home.businessAddressLine1}
                  {
                    home.businessAddressLine2 !== null ? (<div>{home.businessAddressLine2}</div>): null
                  }
                  <div>{home.city}, {home.state} - {home.zipcode}</div>
                </MDBCardSubTitle>
              </MDBCardBody>
              <MDBCardFooter>
                Added On: {convertIsoToDate(home.createdAt)}
                {
                    !home.active ? (
                      <div className='float-end'>
                        <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title="Home will be excluded from neighorhood dashboard. To make it active, edit home details.">
                          <span style={{color: 'red'}}>Home is not Active</span>
                        </MDBTooltip>
                      </div>)
                      : null
                  }
              </MDBCardFooter>
          </MDBCard>
        ))}
      </div>
    )
  }

  documentListHTML() {
    return (
      <div>
        <h4>List of Documents:</h4>
        {
          this.state.documents.map((document: Document, index: number) => (
          <MDBCard style={{margin: "1em"}}>
              <MDBCardBody>
                <MDBCardText>
                  {document.documentTitle}
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter className='text-muted'>
                {convertIsoToDate(document.createdAt)}
                <div className='float-end'>
                  <span onClick={() => this.downloadDocument(document)} title='Download'>
                    <MDBIcon fas icon="cloud-download-alt" style={{color: "gray", cursor: "pointer"}}/>
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span onClick={() => this.deleteDocument(document)} title='Delete'>
                    <MDBIcon fas icon="trash-alt" style={{color: "red", cursor: "pointer"}}/>
                  </span>
                </div>
              </MDBCardFooter>
            </MDBCard>
          ))
        }
      </div>
    )
  }
  
  render() {
    return (
      <div style={{textAlign: 'center'}} className="pageContainer">
        {
          this.state.businessHomes.length === 0 ? (
            this.noHomeListHTML()
          ): (
            this.homeListHTML()
          )
        }
          
        <MDBModal open={this.state.addNewHomeModalVisiblility} onClose={()=>this.toggleNewHomeModalVisiblility(false)} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Business Home Details</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={()=>this.toggleNewHomeModalVisiblility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBValidation className="row g-3 needs-validation">
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Business Name"
                      name="businessName"
                      id="business-name"
                      type="text"
                      value={this.state.businessHomeDetails.businessName}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <h6>Home Provider Details</h6>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Provider First Name"
                      name="providerFirstName"
                      id="provider-first-name"
                      type="text"
                      value={this.state.businessHomeDetails.providerFirstName}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Provider Last Name"
                      name="providerLastName"
                      id="provider-last-name"
                      type="text"
                      value={this.state.businessHomeDetails.providerLastName}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Provider Email"
                      name="providerEmail"
                      id="provider-email"
                      type="email"
                      value={this.state.businessHomeDetails.providerEmail}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Provider Phone Number"
                      name="providerPhoneNumber"
                      id="provider-phone-number"
                      type="number"
                      value={this.state.businessHomeDetails.providerPhoneNumber}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <h6>Home Contact Details</h6>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Business Phone Number"
                      name="businessPhoneNumber"
                      id="business-phone-number"
                      type="number"
                      value={this.state.businessHomeDetails.businessPhoneNumber}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Business Fax Number"
                      name="businessFaxNumber"
                      id="business-fax-number"
                      type="number"
                      value={this.state.businessHomeDetails.businessFaxNumber}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Address Line 1"
                      name="businessAddressLine1"
                      id="business-address-line-1"
                      type="text"
                      value={this.state.businessHomeDetails.businessAddressLine1}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Address Line 2"
                      name="businessAddressLine2"
                      id="business-address-line-2"
                      type="text"
                      value={this.state.businessHomeDetails.businessAddressLine2}
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="City"
                      name="city"
                      id="city"
                      type="text"
                      value={this.state.businessHomeDetails.city}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="State"
                      name="state"
                      id="state"
                      type="text"
                      value={this.state.businessHomeDetails.state}
                      required
                      disabled
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="Country"
                      name="country"
                      id="country"
                      type="text"
                      value={this.state.businessHomeDetails.country}
                      required
                      disabled
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  <MDBValidationItem feedback="" invalid>
                    <MDBInput
                      label="zipcode"
                      name="zipcode"
                      id="zipcode"
                      type="text"
                      value={this.state.businessHomeDetails.zipcode}
                      required
                      onChange={(event) => this.setBusinessHomeDetails(event)}
                    />
                  </MDBValidationItem>
                  {
                    this.state.editEnabled ? (
                      <MDBValidationItem feedback="" invalid>
                        <MDBCheckbox 
                        name='active' 
                        checked={this.state.businessHomeDetails.active} 
                        id='active' 
                        label='Active' 
                        onChange={(event) => {
                          this.setState({ businessHomeDetails: {...this.state.businessHomeDetails, [event.target.name]: event.target.checked }})
                        }}/>
                      </MDBValidationItem>
                    ): null
                  }
                  
                  <MDBModalFooter>
                    <MDBBtn color='secondary' onClick={()=>this.toggleNewHomeModalVisiblility(false)}>
                      Cancel
                    </MDBBtn>
                    <MDBBtn type='submit' onClick={(e) => this.submitNewHomeDetails(e)}>Submit</MDBBtn>
                    <div style={{color: 'red'}}>{this.state.errorMessageAddNewHome}</div>
                  </MDBModalFooter>
                </MDBValidation>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal open={this.state.documentModalVisiblility} onClose={()=>this.toggleDocumentModalVisibility(false)} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Documents Uploaded: {this.state.documents.length}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={()=>this.toggleDocumentModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBCard style={{margin: "1em"}}>
                  <MDBCardBody>
                    <MDBCardTitle>
                      Add new documents
                    </MDBCardTitle>
                    
                    <MDBCardText>
                      <MDBFile id='customFileNewDocuments' onChange={(e)=>this.handleNewDocuments(e)} multiple/>
                        <br />
                      <MDBBtn onClick={(e)=>this.onSubmitNewDocuments(e)} disabled={this.state.newDocumentUploadButtonDisable}>Upload</MDBBtn>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
                {
                  this.state.documents.length > 0 ? (this.documentListHTML()): null
                }
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    )
  };
}

export default DashboardProvider;
