import * as React from "react";
import { GenericModal } from "../general/genericModal";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";
import { isNullEmptyUndefinedString } from "../../utils/validationUtils";
import {
  EXPIRED_MFA_ERROR_MESSAGE,
  INVALID_VERIFICATION_CODE_ERROR_MESSAGE,
} from "../../constants/message";
import styled from "styled-components";
import { SubmitButton } from "../general/submitButton";
import { CountDownTimer } from "../../components/login/countDownTimer";

const ResendVerificationLinkContainer = styled.a`
  &:hover {
    cursor: pointer !important;
    color: #0073bb !important;
  }
  color: #ff6600 !important;
  font-size: 0.875rem !important;
`;

export interface ILoginActionModal {
  authModal: boolean;
  submitAuth: (authInfo: string) => Promise<any>;
  anotherAuthCode: () => Promise<any>;
  dismiss: () => any;
  authLoading: boolean;
  errorMessage?: string;
}

class LoginAuthModal extends React.Component<ILoginActionModal, any> {
  private readonly VERIFICATION_EXPIRE_SECONDS = 120;

  constructor(props: any) {
    super(props);
    this.state = {
      authInfo: "",
      authErrorMessage: "",
      authInfoMessage: "",
      timerStatus: false,
      showResendCode: false,
    };
    this.authInfoOnChange = this.authInfoOnChange.bind(this);
    this.anotherAuthCode = this.anotherAuthCode.bind(this);
    this.submit = this.submit.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.changeShowResendCode = this.changeShowResendCode.bind(this);
    this.changeTimerStatue = this.changeTimerStatue.bind(this);
  }

  authInfoOnChange(authInfo: string) {
    this.setState({
      authInfo: authInfo,
    });
  }

  async submit() {
    const { authInfo } = this.state;
    this.setState({
      authErrorMessage: "",
      authInfoMessage: "",
    });
    try {
      if (isNullEmptyUndefinedString(authInfo)) {
        this.setState({
          authInfoMessage: "",
          authErrorMessage: "Please provide a valid verification code.",
        });
        return;
      }
      await this.props.submitAuth(authInfo);
      this.resetAuthInfo();
    } catch (e) {
      this.setState({
        authInfoMessage: "",
        authErrorMessage: this.props.errorMessage,
      });
    }
  }

  async anotherAuthCode() {
    const { anotherAuthCode } = this.props;
    try {
      await anotherAuthCode();
      this.setState({
        authErrorMessage: "",
        authInfoMessage:
          "New code sent. Please use the most recent code to verify account.",
        timerStatus: true,
      });
    } catch (e: any) {
      this.setState({
        authInfoMessage: "",
        authErrorMessage: e.message,
      });
    }
    this.setState(
      {
        authInfo: "",
      },
      () => this.changeShowResendCode(false),
    );
  }

  dismiss() {
    this.props.dismiss();
    this.resetAuthInfo();
  }

  resetAuthInfo() {
    this.setState({
      authInfo: "",
      authErrorMessage: "",
      authInfoMessage: "",
    });
  }

  changeShowResendCode(isShow: boolean) {
    this.setState({ showResendCode: isShow });
  }

  changeTimerStatue(status: any) {
    this.setState({ timerStatus: status });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.authModal !== this.props.authModal) {
      this.resetAuthInfo();
      this.setState(
        {
          timerStatus: this.props.authModal,
        },
        () => this.changeShowResendCode(false),
      );
    }

    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        authErrorMessage: this.props.errorMessage,
        loading: false,
      });
      if (this.props.errorMessage === EXPIRED_MFA_ERROR_MESSAGE) {
        this.setState({
          timerStatus: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this.resetAuthInfo();
  }

  render() {
    //State
    const { authInfo, authErrorMessage, authInfoMessage } = this.state;
    const { authModal } = this.props;

    const translationPrefix = "authModal.";

    // Action
    return (
      <GenericModal
        key="login-auth-modal"
        isVisible={authModal}
        maxWidth={600}
        header={<h4 className="input-label">Verification Code</h4>}
        body={
          <div className="modal-body">
            <div className="p-b-10">
              Enter the verification code sent to your email.
            </div>
            <div
              className="p-b-10"
              style={{ fontStyle: "italic", fontSize: 14 }}
            ></div>
            <div
              className="p-b-10"
              style={{ fontStyle: "italic", fontSize: 14 }}
            >
              {/* One-time passcode (OTP) through voice call is limited to five times within 24 hours. Your account will be locked once the limit is reached. This limit is not applicable when OTP is received via text messages. */}
            </div>

            <div className="form-container m-b-0">
              <div className="row align-items-end">
                <div className="col-sm-12 col-md">
                  <div className="input-container">
                    <MDBInput
                      label="Code"
                      id="sign-in-code"
                      type="number"
                      value={authInfo}
                      required
                      onChange={(e) => this.authInfoOnChange(e.target.value)}
                    />
                  </div>
                  <div className="input-label-container">
                    <div>
                      <CountDownTimer
                        message="The verification code expires in "
                        initTimer={this.VERIFICATION_EXPIRE_SECONDS}
                        timerStatus={this.state.timerStatus}
                        changeShowResendCode={(isShow) => {
                          this.changeShowResendCode(isShow);
                        }}
                        changeTimerStatus={(status) => {
                          this.changeTimerStatue(status);
                        }}
                      />
                    </div>
                    <div>
                      {this.state.showResendCode === true && (
                        <ResendVerificationLinkContainer
                          id="resendAuthCode"
                          onClick={this.anotherAuthCode}
                        >
                          Resend Code
                        </ResendVerificationLinkContainer>
                      )}
                    </div>
                  </div>
                  <div style={{ color: "red" }}>
                    {this.state.authErrorMessage}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        footer={[
          <MDBBtn type="submit" className="me-1" onClick={(e) => this.submit()}>
            Submit
          </MDBBtn>,
          <MDBBtn
            color="danger"
            className="me-1"
            onClick={(e) => this.dismiss()}
          >
            Cancel
          </MDBBtn>,
        ]}
      />
    );
  }
}

export default LoginAuthModal;
