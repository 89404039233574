// import { CognitoUser } from "amazon-cognito-identity-js";
import { MDBBtn } from "mdb-react-ui-kit";
import { observer } from "mobx-react";
import React from "react";
import { RouterAddress } from "../constants/RouterAddress";

@observer
class NotSignedIn extends React.Component {

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div style={{textAlign: "center"}} className="p-5">
        <div>
          Oops.... Seems this page doesn't exist. Click below to go to login page.
        </div>
        <MDBBtn href={RouterAddress.LOGIN_PAGE}>
          Home Page
        </MDBBtn>
      </div>
    )
  }
}

export default NotSignedIn;
