import { matchPath } from "react-router";

export class RouterAddress {
  static ROUTE_PARAM_BUSINESS_HOME_ID = ":businessHomeRangeId";
  public static LOGIN_PAGE = "/";
  public static HOME = `/home`;
  public static DASHBOARD_PROVIDER = `/home/provider`;
  public static DASHBOARD_HOME_CAREGIVER = `/home/:businessHomeRangeId/caregiver`;
  public static DASHBOARD_HOME_RESIDENTS = `/home/:businessHomeRangeId/residents`;

  public static NOT_SIGNED_IN = `/public`;

  public static requireAuth(pathname: string): boolean {
    return [RouterAddress.HOME].some((route) => matchPath(pathname, route));
  }

  public static getHomeCaregiverDashboardPagePath(houseRangeId: string): string {
    return RouterAddress.DASHBOARD_HOME_CAREGIVER.replace(RouterAddress.ROUTE_PARAM_BUSINESS_HOME_ID, houseRangeId);
  }

  public static getHomeResidentsDashboardPagePath(houseRangeId: string): string {
    return RouterAddress.DASHBOARD_HOME_RESIDENTS.replace(RouterAddress.ROUTE_PARAM_BUSINESS_HOME_ID, houseRangeId);
  }
}
